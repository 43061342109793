import { AlphaConsoleSideMenuItem } from '@netfront/ui-library';

export const SIDEBAR_MENU_ITEMS_TOP_LINK: AlphaConsoleSideMenuItem = {
  id: 'dashboard',
  label: 'Dashboard',
};

export const SIDEBAR_MENU_ITEMS: AlphaConsoleSideMenuItem[] = [
  { id: 'style', label: 'Style and layout' },
  { id: 'integrations', label: 'Install codes' },
  { id: 'reporting', label: 'Reporting' },
  { id: 'support', label: 'Support', href: '/contact' },
  { id: 'resources', label: 'Resources', href: '/docs' },
  { id: 'team', label: 'Team' },
];

export const SIDEBAR_MENU_ITEMS_READ_ONLY: AlphaConsoleSideMenuItem[] = [{ id: 'resources', label: 'Resources', href: '/docs' }];
