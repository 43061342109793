import { useContext, useEffect, useState } from 'react';

import { AlphaConsoleSideMenuItem } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { SIDEBAR_MENU_ITEMS, SIDEBAR_MENU_ITEMS_READ_ONLY, SIDEBAR_MENU_ITEMS_TOP_LINK } from './useGetSideMenu.constants';
import { IUseGetSideMenu } from './useGetSideMenu.interfaces';

import { PermissionContext } from '../../../contexts';

export const useGetSideMenu = (): IUseGetSideMenu => {
  const { hasPermission } = useContext(PermissionContext);

  const {
    query: { projectId, organisationKey },
  } = useRouter();

  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);
  const [leftSideBarLinks, setLeftSideBarLinks] = useState<AlphaConsoleSideMenuItem[]>(SIDEBAR_MENU_ITEMS);
  const [topSideMenuLink, setTopSideMenuLink] = useState<AlphaConsoleSideMenuItem>(SIDEBAR_MENU_ITEMS_TOP_LINK);

  topSideMenuLink;
  const leftSideBarDynamicUrlMap = {
    dashboard: `/dashboard/${String(organisationKey)}/${String(projectId)}`,
    style: `/dashboard/${String(organisationKey)}/${String(projectId)}/style-and-layout`,
    integrations: `/dashboard/${String(organisationKey)}/${String(projectId)}/integrations`,
    reporting: `/dashboard/${String(organisationKey)}/${String(projectId)}/reporting`,
    team: `${String(process.env.REACT_APP_IDENTITY_SERVER_URL)}/project/${String(projectId)}/user`,
    payment: `/dashboard/${String(organisationKey)}/${String(projectId)}/payment`,
  };

  useEffect(() => {
    setHasEditPermission(hasPermission(String(projectId), 'EDIT'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const menuItems = hasEditPermission ? SIDEBAR_MENU_ITEMS : SIDEBAR_MENU_ITEMS_READ_ONLY;

    setTopSideMenuLink({
      ...topSideMenuLink,
      href: leftSideBarDynamicUrlMap['dashboard'],
    });

    setLeftSideBarLinks(
      menuItems.map(({ href, icon, id, label }) => ({
        href: href ? href : leftSideBarDynamicUrlMap[String(id)],
        icon,
        id,
        label,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEditPermission]);

  return {
    leftSideBarLinks,
    topSideMenuLink,
  };
};
