import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS, useMediaQuery } from '@netfront/common-library';
import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import {
  useDomain,
  useGetGeladaProject,
  useGetProduct,
  useIdentitySiteUrls,
  useProtectedRoute,
  useUser,
} from '@netfront/gelada-identity-library';
import {
  AvatarBreadcrumbSectionTemplate,
  Button,
  Checkbox,
  Dialog,
  InformationBox,
  Label,
  LinkButton,
  SideBar,
} from '@netfront/ui-library';
import { CardCvcElement, CardNumberElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import noop from 'lodash.noop';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { ADD_CARD_ID } from './PaymentPage.constants';
import { getPlanDescription } from './PaymentPage.helpers';

import {
  IDBDowngradeRequest,
  useCreatePaymentMethod,
  useDeletePaymentMethod,
  useGetActivePlans,
  useGetMuriquiProject,
  useGetPlans,
  useGetStripePaymentMethods,
  useToast,
  useUpdateDefaultPaymentMethod,
  useUpdateProjectSubscription,
} from '../../../hooks';
import { IPlan, IStripePaymentMethod } from '../../../interfaces';
import { addUserToEmailSubscription } from '../../../utils';
import { LeftSideBarLinks } from '../../LeftSidebarLinks';
import { BUTTON_CLASSES } from '../../LinkButton';
import { PageLayout } from '../../PageLayout';
import { PaymentCardOption, IDeletableCardDetails } from '../../PaymentCardOption';
import { ISelectedProjectPlan, ProPlanCard, PDF_PRODUCT_ID, SPEECH_PRODUCT_ID, TRANSLATE_PRODUCT_ID } from '../../ProPlanCard';

const PaymentPage = () => {
  const { isDomainReady } = useDomain();
  const { trackEnhanceConversionsForUser, trackEvent } = useGoogleAnalytics();
  const { getGoogleAdwordsConversionId, getGoogleAdwordsPurchaseConversionLabel } = useGetProduct();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    push,
    query: { organisationKey, projectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();
  const { getUser } = useUser();

  const elements = useElements();

  const isMobile = useMediaQuery({
    size: 'small',
  });

  const stripe = useStripe();

  const planRef = useRef<ISelectedProjectPlan>();

  const [activeCard, setActiveCard] = useState<string>(ADD_CARD_ID);
  const [areTermsAndConditionsAccepted, setAreTermsAndConditionsAccepted] = useState<boolean>(false);
  const [cardIdToDelete, setCardIdToDelete] = useState<string | undefined>();
  const [deletableCard, setDeletableCard] = useState<IDeletableCardDetails>({} as IDeletableCardDetails);
  const [downgradeRequest, setDowngradeRequest] = useState<IDBDowngradeRequest | undefined>();
  const [hasPaymentForm, setHasPaymentForm] = useState<boolean>(true);
  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isDeleteDefaultModalOpen, setIsDeleteDefaultModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDowngradeRequestModalOpen, setIsDowngradeRequestModalOpen] = useState<boolean>(false);
  const [isLeftSideBarOpen, setIsLeftSideBarOpen] = useState<boolean>(true);
  const [plan, setPlan] = useState<ISelectedProjectPlan>();
  const [plans, setPlans] = useState<IPlan[]>();
  const [projectName, setProjectName] = useState<string>();
  const [stripePaymentMethods, setStripePaymentMethods] = useState<IStripePaymentMethod[]>([]);

  const closeDeleteDialog = () => {
    setDeletableCard({} as IDeletableCardDetails);
    setIsDeleteModalOpen(false);
    setIsDeleteDefaultModalOpen(false);
  };

  const handleAddNewCardClick = () => {
    setHasPaymentForm(!hasPaymentForm);
  };

  const handleApolloError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCardChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setActiveCard(value);
  };

  const handleDeleteClick = (card: IDeletableCardDetails) => {
    if (isEmpty(card)) {
      return;
    }

    const { default: isDefaultCard } = card;

    setDeletableCard(card);
    isDefaultCard ? setIsDeleteDefaultModalOpen(true) : setIsDeleteModalOpen(true);
  };

  const handlePlanChange = (selectedPlan: ISelectedProjectPlan) => {
    setPlan(selectedPlan);
  };

  const handleSubmitWithNewCard = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      return;
    }

    stripe
      .createToken(cardNumberElement)
      .then(({ token }) => {
        if (!token) {
          return;
        }

        handleCreatePaymentMethod({
          product: 'KANZI',
          projectId: String(projectId),
          token: token.id,
        });
      })
      .catch((error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      });
  };

  const handleTermsAndConditionsChange = () => {
    setAreTermsAndConditionsAccepted(!areTermsAndConditionsAccepted);
  };

  const handleDowngradeRequestModalOpenClose = () => {
    setIsDowngradeRequestModalOpen(false);
  };

  const { handleGetMuriquiProject } = useGetMuriquiProject({
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {
      const {
        customerDetail: { subscriptions },
      } = project;

      if (!subscriptions?.length) {
        return;
      }

      const subscriptionWithDowngradeRequest = subscriptions.find((subscription) => Boolean(subscription.downgradeRequest));

      if (!subscriptionWithDowngradeRequest) {
        return;
      }

      const { downgradeRequest: request } = subscriptionWithDowngradeRequest;

      if (!request) {
        return;
      }

      setDowngradeRequest(request);
      setIsDowngradeRequestModalOpen(true);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreatePaymentMethod, isLoading: isCreatingPaymentMethodLoading = false } = useCreatePaymentMethod({
    onCompleted: ({ paymentMethod }) => {
      const { stripeSourceId } = paymentMethod;

      setActiveCard(stripeSourceId);
      setStripePaymentMethods([...stripePaymentMethods, paymentMethod]); // Need to update new default in object. Will require payload update in API

      handleUpdateDefaultPaymentMethod({
        cardId: stripeSourceId,
        product: 'KANZI',
        projectId: String(projectId),
      });
    },
    onError: handleApolloError,
  });

  const { handleDeletePaymentMethod } = useDeletePaymentMethod({
    onCompleted: () => {
      const filteredStripePaymentMethods = stripePaymentMethods.filter((card) => card.stripeSourceId !== cardIdToDelete);

      if (!filteredStripePaymentMethods.length) {
        setHasPaymentForm(true);

        push(`/dashboard/${String(organisationKey)}/${String(projectId)}`).catch((error) => {
          handleToastError({
            error,
          });
        });

        handleToastSuccess({
          message:
            'Paid subscription has been stopped -> Your subscription has been downgraded, your plan will revert to the free tier at the end of the billing period',
        });

        return;
      }

      setStripePaymentMethods(filteredStripePaymentMethods);

      closeDeleteDialog();

      handleToastSuccess({
        message: 'Card successfully removed',
      });
    },
    onError: handleApolloError,
  });

  const { handleGetActivePlans, isLoading: isGetActivePlansLoading = false } = useGetActivePlans({
    onCompleted: ({ plans: activePlans = [] }) => {
      const projectPlan: ISelectedProjectPlan = {
        pdf: activePlans.find(({ productId }) => productId === PDF_PRODUCT_ID),
        speech: activePlans.find(({ productId }) => productId === SPEECH_PRODUCT_ID),
        totalPrice: 0,
        translate: activePlans.find(({ productId }) => productId === TRANSLATE_PRODUCT_ID),
      };

      planRef.current = projectPlan;

      setPlan(projectPlan);
    },
    onError: handleApolloError,
  });

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading = false } = useGetGeladaProject({
    onCompleted: ({ geladaProject: { name } }) => {
      setProjectName(name);
    },
    onError: handleApolloError,
  });

  const { handleGetPlans, isLoading: isGetPlansLoading = false } = useGetPlans({
    onCompleted: ({ plans: returnedPlans }) => {
      setPlans([...returnedPlans].sort((a, b) => Number(a.sort) - Number(b.sort)));

      handleGetActivePlans({
        product: 'KANZI',
        projectId: String(projectId),
      });
    },
    onError: handleApolloError,
  });

  const { handleGetStripePaymentMethods, isLoading: isGetStripePaymentMethodsLoading = false } = useGetStripePaymentMethods({
    onCompleted: ({ stripePaymentMethods: returnedStripePaymentMethods }) => {
      const defaultCard = returnedStripePaymentMethods.find(({ default: isDefault }) => isDefault);

      setActiveCard(defaultCard ? defaultCard.stripeSourceId : ADD_CARD_ID);
      setHasRequiredPageData(true);
      setStripePaymentMethods(returnedStripePaymentMethods);
    },
    onError: handleApolloError,
  });

  const { handleUpdateDefaultPaymentMethod, isLoading: isUpdateDefaultPaymentMethodLoading = false } = useUpdateDefaultPaymentMethod({
    onCompleted: ({ isCompleted }) => {
      if (!plan) {
        return;
      }

      if (isCompleted) {
        const { pdf, speech, translate } = plan;

        const planIds = [];

        if (pdf) {
          planIds.push(pdf.id);
        }

        if (speech) {
          planIds.push(speech.id);
        }

        if (translate) {
          planIds.push(translate.id);
        }

        const googleAdwordsConversionId = getGoogleAdwordsConversionId('KANZI');
        const googleAdwordsSignUpConversionLabel = getGoogleAdwordsPurchaseConversionLabel('KANZI');

        // TODO: The backend needs to be updated to be able to detect if the plan has been downgraded
        if (googleAdwordsConversionId && googleAdwordsSignUpConversionLabel) {
          const user = getUser();

          trackEnhanceConversionsForUser(user?.email, user?.firstName, user?.lastName);

          trackEvent('conversion', {
            currency: 'AUD',
            send_to: `${googleAdwordsConversionId}/${googleAdwordsSignUpConversionLabel}`,
            transaction_id: '',
            value: plan.totalPrice,
          });
        }

        handleUpdateProjectSubscription({
          planIds,
          product: 'KANZI',
          projectId: String(projectId),
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: handleApolloError,
  });

  const { handleUpdateProjectSubscription, isLoading: isUpdateProjectSubscriptionLoading = false } = useUpdateProjectSubscription({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Subscription updated',
      });

      if (plan) {
        const { translate, speech, pdf } = plan;

        const user = getUser();

        if (user) {
          addUserToEmailSubscription({
            customFields: {
              PDF: pdf?.name === 'Free' ? 'Free' : 'Paid',
              Speech: speech?.name === 'Free' ? 'Free' : 'Paid',
              Translate: translate?.name === 'Free' ? 'Free' : 'Paid',
            },
            emailAddress: user.email,
            listId: String(process.env.REACT_APP_CREATE_SEND_LIST_ID_REGISTERED),
            name: `${user.firstName} ${user.lastName}`,
          });
        }
      }

      setAreTermsAndConditionsAccepted(false);

      push(`/dashboard/${String(organisationKey)}/${String(projectId)}`).catch((error) => {
        handleToastError({
          error,
        });
      });
    },
    onError: handleApolloError,
  });

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    handleGetGeladaProject({
      projectId: String(projectId),
    });

    void handleGetMuriquiProject({
      projectId: String(projectId),
    });

    void handleGetStripePaymentMethods({
      product: 'KANZI',
      projectId: String(projectId),
    });

    void handleGetPlans({
      product: 'KANZI',
      currency: 'AUD',
    });

    handleGetActivePlans({
      product: 'KANZI',
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId]);

  useEffect(() => {
    setHasPaymentForm(activeCard === ADD_CARD_ID);
  }, [activeCard]);

  useEffect(() => {
    if (!hasPaymentForm) {
      setAreTermsAndConditionsAccepted(false);
    }
  }, [hasPaymentForm]);

  useEffect(() => {
    if (isMobile) {
      setIsLeftSideBarOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(getBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const managementSidebar = (
    <SideBar
      isSideBarOpen={isLeftSideBarOpen}
      revealDirection="left"
      isGreyedOutOverlayDisabled
      onClose={noop}
      onRevealLeftSidebar={() => setIsLeftSideBarOpen(!isLeftSideBarOpen)}
    >
      <LeftSideBarLinks />
    </SideBar>
  );

  const isLoading =
    isCreatingPaymentMethodLoading ||
    isGetActivePlansLoading ||
    isGetPlansLoading ||
    isGetStripePaymentMethodsLoading ||
    isUpdateDefaultPaymentMethodLoading ||
    isUpdateProjectSubscriptionLoading ||
    isGetGeladaProjectLoading;

  return (
    <>
      <PageLayout isPreloaderVisible={isLoading} title="Payments page" hasPrivateLayout>
        {hasRequiredPageData && (
          <div className="c-page-wrapper">
            <div className="c-page__content">
              {projectName && (
                <AvatarBreadcrumbSectionTemplate
                  additionalClassNames="mb-8"
                  breadcrumbItems={[
                    {
                      key: '0',
                      content: (
                        <LinkButton
                          additionalClassNames={`${BUTTON_CLASSES['subtle-link']} color-black`}
                          text="Dashboard"
                          url={`${identityUrl}/dashboard`}
                        />
                      ),
                    },
                    {
                      key: '1',
                      content: <span>Plans &amp; Billing</span>,
                    },
                  ]}
                  title={projectName}
                />
              )}

              <InformationBox message="Our payment system uses Stripe, we don't store any credit card information on our servers. For more information on Stripe see here or visit our privacy statement" />

              {plan && (
                <div className="flex flex-wrap -mx-8 pt-20 pb-24">
                  {plans && (
                    <div className="w-full md:w-1/3 px-8">
                      <ProPlanCard
                        hasCTA={false}
                        pdf={plan.pdf}
                        plans={plans}
                        speech={plan.speech}
                        title="Plan selection"
                        translate={plan.translate}
                        fullWidth
                        onPlanChange={handlePlanChange}
                      />
                    </div>
                  )}

                  <div className="flex-1 px-8">
                    {!isEmpty(plan) && (
                      <div className="bg-grey-200 rounded p-8 mb-8">
                        <h2 className="h3">Order summary</h2>
                        <p>
                          <strong>Translate</strong> ({getPlanDescription('translate', plan)})
                        </p>
                        <p>
                          <strong>Speech</strong> ({getPlanDescription('speech', plan)})
                        </p>
                        <p>
                          <strong>PDF</strong> ({getPlanDescription('pdf', plan)})
                        </p>
                        <div className="flex">
                          <strong className="flex-1 h4">Total</strong>
                          <span>{plan.totalPrice === 0 ? '$0' : `$${plan.totalPrice.formatWithThousandsSeparator()}`}</span>
                        </div>
                      </div>
                    )}

                    <div className="flex items-center mb-8 flex-col lg:flex-row">
                      <h2 className="h3 mb-0 flex-1">Authorise payment</h2>

                      <div className="flex items-stretch">
                        <div className="flex items-center py-2 px-4 border border-grey-300 rounded ml-2">
                          <img alt="American express cards accepted" loading="lazy" src="/images/american-express-logo.svg" />
                        </div>
                        <div className="flex items-center py-2 px-4 border border-grey-300 rounded ml-2">
                          <img alt="Visa cards accepted" loading="lazy" src="/images/visa-logo.svg" />
                        </div>
                        <div className="flex items-center py-2 px-4 border border-grey-300 rounded ml-2">
                          <img alt="Mastercard accepted" loading="lazy" src="/images/mastercard-logo.svg" />
                        </div>
                        <div className="flex items-center py-2 px-4 border border-grey-300 rounded ml-2">
                          <small className="weight-700 mr-2">Powered by Stripe</small>
                          <img alt="Stripe logo" loading="lazy" src="/images/stripe-logo.svg" />
                        </div>
                      </div>
                    </div>

                    <fieldset className="p-0 mb-6">
                      <legend className="h-hide-visually">Payment methods</legend>
                      {stripePaymentMethods.map(({ cardBrand, default: isDefaultCard, lastFourDigits, stripeSourceId }) => {
                        return (
                          <PaymentCardOption
                            key={stripeSourceId}
                            cardBrand={cardBrand}
                            default={isDefaultCard}
                            isDeletable={stripePaymentMethods.length === 1 || !isDefaultCard}
                            lastFourDigits={lastFourDigits}
                            selectedValue={activeCard}
                            stripeSourceId={stripeSourceId}
                            onChange={handleCardChange}
                            onDelete={handleDeleteClick}
                          />
                        );
                      })}
                    </fieldset>

                    <Dialog
                      isOpen={isDowngradeRequestModalOpen}
                      title="Subscription update"
                      onClose={handleDowngradeRequestModalOpenClose}
                      onConfirm={handleDowngradeRequestModalOpenClose}
                    >
                      <p>
                        You have chosen to downgrade your plan. Your plan will maintain the increased limit until the end of the billing
                        period ({downgradeRequest && format(new Date(downgradeRequest.date), 'dd/MM/yyyy')}
                        ).
                      </p>
                    </Dialog>

                    <Dialog
                      isOpen={isDeleteModalOpen}
                      title="Remove card"
                      onCancel={closeDeleteDialog}
                      onClose={closeDeleteDialog}
                      onConfirm={() => {
                        setCardIdToDelete(deletableCard.stripeSourceId);
                        handleDeletePaymentMethod({
                          cardId: deletableCard.stripeSourceId,
                          product: 'KANZI',
                          projectId: String(projectId),
                        });
                      }}
                    >
                      <p>Are you sure you want to remove this card?</p>
                    </Dialog>

                    <Dialog
                      isOpen={isDeleteDefaultModalOpen}
                      title="Remove default card"
                      onCancel={closeDeleteDialog}
                      onClose={closeDeleteDialog}
                      onConfirm={() => {
                        setCardIdToDelete(deletableCard.stripeSourceId);
                        handleDeletePaymentMethod({
                          cardId: deletableCard.stripeSourceId,
                          product: 'KANZI',
                          projectId: String(projectId),
                        });
                      }}
                    >
                      <p>Are you sure you want to remove this card?</p>
                      <p>Removing this card will revert your account to the free tiers</p>
                    </Dialog>

                    {stripePaymentMethods.length > 0 && (
                      <div className="text-right mb-8">
                        <Button
                          text={hasPaymentForm ? 'Cancel' : 'Add new card'}
                          variant="primary-inverse"
                          onClick={handleAddNewCardClick}
                        />
                      </div>
                    )}

                    {hasPaymentForm ? (
                      <form onSubmit={handleSubmitWithNewCard}>
                        <Label forId="cardNumber" labelText="Card number *" />
                        <CardNumberElement
                          id="cardNumber"
                          options={{
                            classes: {
                              base: 'border-2 border-grey-200 hover:border-2 hover:border-grey-200 c-input mb-4',
                              complete: 'border-2 border-primary',
                            },
                          }}
                        />
                        <div className="flex items-center mb-2">
                          <div className="mr-4">
                            <Label forId="cardExpiry" labelText="Card number *" />
                            <CardExpiryElement
                              id="cardExpiry"
                              options={{
                                classes: {
                                  base: 'border-2 border-grey-200 hover:border-2 hover:border-grey-200 c-input mb-4',
                                  complete: 'border-2 border-primary',
                                },
                              }}
                            />
                          </div>
                          <div>
                            <Label forId="cardCVC" labelText="Card number *" />
                            <CardCvcElement
                              id="cardCVC"
                              options={{
                                classes: {
                                  base: 'border-2 border-grey-200 hover:border-2 hover:border-grey-200 c-input mb-4',
                                  complete: 'border-2 border-primary',
                                },
                              }}
                            />
                          </div>
                        </div>

                        <label className="flex items-center mb-6" htmlFor="terms">
                          <Checkbox
                            id="terms"
                            isChecked={areTermsAndConditionsAccepted}
                            labelText="I agree to the terms and conditions"
                            name="terms"
                            value="terms"
                            isLabelHidden
                            onChange={handleTermsAndConditionsChange}
                          />
                          I agree to the <NextLink href="/terms-and-conditions">&nbsp;terms and conditions</NextLink>
                        </label>

                        <div className="flex justify-end gap-4">
                          <Button
                            linkButton={{
                              id: 'cancel',
                              url: `/dashboard/${String(organisationKey)}/${String(projectId)}`,
                            }}
                            text="Cancel"
                            type="button"
                            variant="danger--tertiary"
                          />
                          <Button
                            isDisabled={!areTermsAndConditionsAccepted || isCreatingPaymentMethodLoading}
                            text="Complete order"
                            type="submit"
                            variant="primary"
                          />
                        </div>
                      </form>
                    ) : (
                      <div className="flex justify-end gap-4">
                        <Button
                          linkButton={{
                            id: 'cancel',
                            url: `/dashboard/${String(organisationKey)}/${String(projectId)}`,
                          }}
                          text="Cancel"
                          type="button"
                          variant="danger--tertiary"
                        />
                        <Button
                          text="Complete order"
                          type="submit"
                          variant="primary"
                          onClick={() => {
                            handleUpdateDefaultPaymentMethod({
                              cardId: activeCard,
                              product: 'KANZI',
                              projectId: String(projectId),
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </PageLayout>

      {!isLoading && managementSidebar}
    </>
  );
};

export { PaymentPage };
