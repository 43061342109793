import classNames from 'classnames';
import NextLink from 'next/link';

import { PageUrlLinkProps } from './PageUrlLink.interfaces';

const PageUrlLink = ({ isPrevious, title, url }: PageUrlLinkProps) => {
  return (
    <NextLink href={url}>
      <a
        className={classNames('p-3 bg-white border border-grey-300 rounded flex flex-col bg-white transition hover:bg-grey-200', {
          'ml-auto': !isPrevious,
        })}
      >
        <span className="text-uppercase text-sm">{isPrevious ? 'Prev' : 'Next'}</span>
        <span className="weight-600">{title}</span>
      </a>
    </NextLink>
  );
};

export { PageUrlLink };
