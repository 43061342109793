import React, { useContext, useEffect, useState } from 'react';

import { Icon } from '@netfront/ui-library';
import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { LEFT_SIDEBAR_LIST, LEFT_SIDEBAR_LIST_READ_ONLY } from './LeftSidebarLinks.constants';
import { ILeftSidebarLinks } from './LeftSidebarLinks.interfaces';

import { PermissionContext } from '../../contexts';

const LeftSideBarLinks = () => {
  const { hasPermission } = useContext(PermissionContext);

  const {
    asPath,
    query: { projectId, organisationKey },
  } = useRouter();

  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);
  const [leftSideBarLinks, setLeftSideBarLinks] = useState<ILeftSidebarLinks[]>(LEFT_SIDEBAR_LIST);
  const [path, setPath] = useState<string>('');

  const leftSideBarDynamicUrlMap = {
    dashboard: `/dashboard/${String(organisationKey)}/${String(projectId)}`,
    style: `/dashboard/${String(organisationKey)}/${String(projectId)}/style-and-layout`,
    integrations: `/dashboard/${String(organisationKey)}/${String(projectId)}/integrations`,
    reporting: `/dashboard/${String(organisationKey)}/${String(projectId)}/reporting`,
    team: `${String(process.env.REACT_APP_IDENTITY_SERVER_URL)}/project/${String(projectId)}/user`,
    payment: `/dashboard/${String(organisationKey)}/${String(projectId)}/payment`,
  };

  useEffect(() => {
    if (!asPath) {
      return;
    }

    const pathItems = asPath.split('/');

    setPath(pathItems.length === 4 ? 'dashboard' : String(asPath.split('/').pop()));
  }, [asPath]);

  useEffect(() => {
    setHasEditPermission(hasPermission(String(projectId), 'EDIT'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setLeftSideBarLinks(hasEditPermission ? LEFT_SIDEBAR_LIST : LEFT_SIDEBAR_LIST_READ_ONLY);
  }, [hasEditPermission]);

  return (
    <ul className="c-left-sidebar__list">
      {leftSideBarLinks.map(({ href, iconId, name, value, className }) => (
        <Link key={name} href={href ? href : String(leftSideBarDynamicUrlMap[value.toLocaleLowerCase()])}>
          <a>
            <li
              className={cx('c-left-sidebar__list-item', className, {
                'c-left-sidebar__list-item--active': path.includes(value.toLocaleLowerCase()),
              })}
            >
              <>
                <Icon className="c-left-sidebar__list-item__icon" id={iconId} />
                <span>{name}</span>
              </>
            </li>
          </a>
        </Link>
      ))}
    </ul>
  );
};

export { LeftSideBarLinks };
