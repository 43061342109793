import { IOrganisationFormFields } from './OrganisationForm.interfaces';

const ORGANISATION_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-organisation-form',
});

const ORGANISATION_FORM_DEFAULTS = Object.freeze({
  heading: 'Organisation',
});

const ORGANISATION_FORM_INITIAL_VALUES: IOrganisationFormFields = {
  name: '',
};

export { ORGANISATION_FORM_BLOCK_CSS_IDENTIFIERS, ORGANISATION_FORM_DEFAULTS, ORGANISATION_FORM_INITIAL_VALUES };
