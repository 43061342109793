import { ChangeEvent, useState } from 'react';

import { Button, Checkbox, Input, Label, Select, Tooltip } from '@netfront/ui-library';

import { ColorPicker, ColorResult } from '../ColorPicker';
import { KanziPlugin } from '../KanziPlugin';

import { POSITION_OPTIONS, SIZE_OPTIONS, THEME_OPTIONS } from './StyleSettings.constants';
import { StyleSettingsProps } from './StyleSettings.interfaces';

const StyleSettings = ({ onChange, onSave, projectConfiguration, styles }: StyleSettingsProps) => {
  const { colour = '#4bc2ca', cornerRadius = 0, isCollapse = false, isSticky, offset = 0, position, size, theme } = styles;

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked, name, value, type },
    } = event;

    onChange(name, type === 'checkbox' ? isChecked : value);

    setIsSaveButtonDisabled(false);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    onChange(name, value);

    setIsSaveButtonDisabled(false);
  };

  const handleColorPickerChange = ({ hex }: ColorResult) => {
    onChange('colour', hex);
    setIsSaveButtonDisabled(false);
  };

  const handleOnSave = () => {
    onSave();
    setIsSaveButtonDisabled(true);
  };

  return (
    <div className="c-style-settings">
      <ul className="c-style-settings__list">
        <li className="c-style-settings__item">
          <Select
            id="position"
            labelText="Position"
            name="position"
            options={POSITION_OPTIONS}
            value={position}
            onChange={handleSelectChange}
          />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Control the location of where Kanzi will be displayed to the user on their device"
          />
        </li>
        <li className="c-style-settings__item">
          <Checkbox
            additionalClassNames="c-style-settings__checkbox"
            id="isSticky"
            isChecked={isSticky}
            labelText="Sticky"
            name="isSticky"
            value="isSticky"
            onChange={handleInputChange}
          />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Control whether the Kanzi will be sticky on the device"
          />
        </li>
        <li className="c-style-settings__item">
          <Checkbox
            additionalClassNames="c-style-settings__checkbox"
            id="isCollapse"
            isChecked={isCollapse}
            labelText="Collapsed"
            name="isCollapse"
            value="isCollapse"
            onChange={handleInputChange}
          />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Minimise the Kanzi tool to reduce the amount of space the tool takes up on screen"
          />
        </li>
        <li className="c-style-settings__item">
          <Select id="size" labelText="Size" name="size" options={SIZE_OPTIONS} value={size} onChange={handleSelectChange} />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Control the size of Kanzi on the screen"
          />
        </li>
        <li className="c-style-settings__item">
          <Label forId="colour" labelText="Colour" />
          <ColorPicker color={colour} id="colour" onChange={handleColorPickerChange} />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Theme the tool to match your primary brand colour"
          />
        </li>
        <li className="c-style-settings__item">
          <Select id="theme" labelText="Theme" name="theme" options={THEME_OPTIONS} value={theme} onChange={handleSelectChange} />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Choose from a light or dark theme"
          />
        </li>
        <li className="c-style-settings__item">
          <Input
            additionalClassNames="max-w-16"
            id="cornerRadius"
            labelText="Corner radius"
            name="cornerRadius"
            type="number"
            value={cornerRadius}
            onChange={handleInputChange}
          />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Control the curve of the tool edges"
          />
        </li>
        <li className="c-style-settings__item">
          <Input
            additionalClassNames="max-w-16"
            id="offset"
            labelText="Offset"
            name="offset"
            type="number"
            value={offset}
            onChange={handleInputChange}
          />
          <Tooltip
            additionalClassNames="c-style-settings__tooltip"
            iconId="id_tooltip_icon"
            placement="top"
            text="Control small adjustments to the control location on the page"
          />
        </li>
      </ul>

      <KanziPlugin projectConfiguration={projectConfiguration} styleSettings={styles} />

      <div className="c-style-settings__footer">
        <Button isDisabled={isSaveButtonDisabled} text="Save changes" variant="cta" onClick={handleOnSave} />
      </div>
    </div>
  );
};

export { StyleSettings };
