import { useState, useEffect } from 'react';

import { useCookie, useGeladaAccessTokenDecoder } from '@netfront/common-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTION,
  useDomain,
  useGetGeladaProject,
  useIdentitySiteUrls,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { AlphaConsoleSideMenu, AvatarBreadcrumbSectionTemplate, InformationBox, LinkButton } from '@netfront/ui-library';
import noop from 'lodash.noop';
import { useRouter } from 'next/router';

import { STYLE_LAYOUT_PAGE_CONSTANTS } from './StyleLayoutPage.constants';

import {
  getPlanUsagePercentage,
  getPlanUsageText,
  IProjectStyleSettings,
  LayoutPreviewer,
  PlanUsage,
  StyleSettings,
} from '../../../components';
import {
  useGetProject,
  useLastProjectVisited,
  useToast,
  useGetSideMenu,
  useUpdateProjectConfiguration,
  useGetProjectConfigurationByProject,
} from '../../../hooks';
import { IDBProject, IProjectConfiguration } from '../../../interfaces';
import { BUTTON_CLASSES } from '../../LinkButton';
import { PageLayout } from '../../PageLayout';

const { pageTitle } = STYLE_LAYOUT_PAGE_CONSTANTS;

const StyleLayoutPage = () => {
  const { createLastProjectVisitedCookie, getAccessTokenCookie, isSecureCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getDecodedJwt, getJwtUserId } = useGeladaAccessTokenDecoder();
  const { leftSideBarLinks, topSideMenuLink } = useGetSideMenu();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { getLastProjectVisitedPath } = useLastProjectVisited();
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    query: { organisationKey, projectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [geladaProjectName, setGeladaProjectName] = useState<string>();
  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [kanziProject, setKanziProject] = useState<IDBProject>();
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [projectConfigurations, setProjectConfigurations] = useState<IProjectConfiguration>({} as IProjectConfiguration);
  const [projectConfigurationId, setProjectConfigurationId] = useState<number>();
  const [projectStyles, setProjectStyles] = useState<IProjectStyleSettings>({} as IProjectStyleSettings);
  const [userId, setUserId] = useState<number>();

  const accessToken = getAccessTokenCookie();

  const handleStyleSettingsChange = (field: string, value: string | boolean) => {
    setProjectStyles(
      (prevState) =>
        ({
          ...prevState,
          [field]: value,
        } as IProjectStyleSettings),
    );

    if (field === 'isCollapse' && value) {
      setProjectStyles(
        (prevState) =>
          ({
            ...prevState,
            isDraggable: false,
          } as IProjectStyleSettings),
      );
    }
  };

  const handleStyleSettingsSave = () => {
    if (!projectConfigurationId) {
      return;
    }

    handleUpdateProjectConfiguration({
      id: projectConfigurationId,
      ...projectStyles,
    });
  };

  const { handleGetProject } = useGetProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ project }) => {
      setKanziProject(project);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetGeladaProject, isLoading: isGetProjectLoading = false } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject: { name } }) => {
      setGeladaProjectName(name);

      handleGetProjectConfigurationByProject({
        projectId: String(projectId),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetProjectConfigurationByProject, isLoading: isGetProjectConfigurationByProjectLoading = false } =
    useGetProjectConfigurationByProject({
      onCompleted: ({ projectConfiguration }) => {
        const { colour, cornerRadius, id, isCollapse, isSticky, offset, position, size, theme } = projectConfiguration;

        setProjectConfigurations(projectConfiguration);
        setProjectConfigurationId(id);

        setProjectStyles({
          colour,
          cornerRadius,
          isCollapse,
          isSticky,
          offset,
          position,
          size,
          theme,
        });

        setHasRequiredPageData(true);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleUpdateProjectConfiguration, isLoading: isUpdateProjectConfigurationLoading = false } = useUpdateProjectConfiguration({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Updated',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const decodedJwt = getDecodedJwt(accessToken);

    setUserId(getJwtUserId(decodedJwt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!(isAuthenticated && isDomainReady && organisationKey && projectId && userId)) {
      return;
    }

    setIdentityUrl(getBaseUrl());
    createLastProjectVisitedCookie({
      optionalCookieAttributesInput: {
        domain: getDomain(),
        secure: isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE),
        storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
      },
      value: getLastProjectVisitedPath(organisationKey, projectId, userId),
    });

    handleGetProject({
      id: String(projectId),
    });

    handleGetGeladaProject({
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady, organisationKey, projectId, userId]);

  const isLoading = isGetProjectLoading || isGetProjectConfigurationByProjectLoading || isUpdateProjectConfigurationLoading;

  return (
    <PageLayout isPreloaderVisible={isLoading} title={pageTitle} hasPrivateLayout>
      {hasRequiredPageData && (
        <div className="c-page-wrapper">
          <div className="c-page__content">
            {kanziProject && (
              <PlanUsage
                plans={[
                  {
                    color: 'var(--g-color-red-400)',
                    label: 'Translate usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                    usage: getPlanUsageText(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                  },
                  {
                    color: 'var(--g-color-orange-200)',
                    label: 'Speech usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                    usage: getPlanUsageText(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                  },
                  {
                    color: 'var(--g-color-blue-200)',
                    label: 'PDF usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedPdf, kanziProject.remainingPdf),
                    usage: getPlanUsageText(kanziProject.allowedPdf, kanziProject.remainingPdf),
                  },
                ]}
              />
            )}
            <div className="flex flex-col mb-8 lg:flex-row lg:items-center">
              {geladaProjectName ? (
                <AvatarBreadcrumbSectionTemplate
                  additionalClassNames="flex-1 mb-8 md:mb-0"
                  breadcrumbItems={[
                    {
                      key: '0',
                      content: (
                        <LinkButton
                          additionalClassNames={`${BUTTON_CLASSES['subtle-link']} color-black`}
                          text="Dashboard"
                          url={`${identityUrl}/dashboard`}
                        />
                      ),
                    },
                    {
                      key: '1',
                      content: <span>{pageTitle}</span>,
                    },
                  ]}
                  title={geladaProjectName}
                />
              ) : null}
            </div>

            <InformationBox
              additionalClassNames="mb-8"
              iconId="id_info_icon"
              message="Configure your KANZI tool position, colour and theme to match your project style."
            />

            <div className="flex flex-1 flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 px-4">
                <h3 className="h4 font-body mb-6">{pageTitle}</h3>
                <StyleSettings
                  projectConfiguration={projectConfigurations}
                  styles={projectStyles}
                  onChange={handleStyleSettingsChange}
                  onSave={handleStyleSettingsSave}
                />
              </div>

              <div className="w-full lg:w-1/2 px-4">
                <LayoutPreviewer colour={projectStyles.colour} position={projectStyles.position} />
              </div>
            </div>
          </div>
        </div>
      )}

      {!isLoading && <AlphaConsoleSideMenu items={leftSideBarLinks} topLink={topSideMenuLink} onClose={noop} />}
    </PageLayout>
  );
};

export { StyleLayoutPage };
