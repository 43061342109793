import { IUpdateOrganisationFormFields } from './UpdateOrganisationForm.interfaces';

const UPDATE_ORGANISATION_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-organisation-form',
});

const UPDATE_ORGANISATION_FORM_DEFAULTS = Object.freeze({
  heading: 'Organisation',
});

const UPDATE_ORGANISATION_FORM_INITIAL_VALUES: IUpdateOrganisationFormFields = {
  name: '',
};

export { UPDATE_ORGANISATION_FORM_BLOCK_CSS_IDENTIFIERS, UPDATE_ORGANISATION_FORM_DEFAULTS, UPDATE_ORGANISATION_FORM_INITIAL_VALUES };
