import { useEffect, useState } from 'react';

import { CMSContentPage, IDBContentPage } from '@netfront/ekardo-content-library';
import { SideBar } from '@netfront/ui-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { DocsContentPageProps } from './DocsContentPage.interfaces';

import { IconMenuLeft } from '../../../components/Icons';
import { PageUrlLink } from '../../../components/PageUrlLink';
import { PUBLISHED } from '../../../constants';
import { PublicPageLayout } from '../../PublicPageLayout';

export const DocsContentPage = ({ contentGroups, contentPage }: DocsContentPageProps) => {
  const {
    query: { pageUrl },
  } = useRouter();

  const [previousPage, setPreviousPage] = useState<IDBContentPage>();
  const [nextPage, setNextPage] = useState<IDBContentPage>();

  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);

  const toggleSideMenu = () => setIsSideMenuOpen(!isSideMenuOpen);

  const onSideMenuClose = () => {
    setIsSideMenuOpen(false);
  };

  useEffect(() => {
    const pages = contentGroups.flatMap((cg) => cg.contentPages).filter(({ status }) => status === PUBLISHED);

    pages.map((page, key) => {
      if (page.url === contentPage.url) {
        setPreviousPage(pages[key - 1]);
        setNextPage(pages[key + 1]);
      }

      return;
    });
  }, [contentGroups, contentPage]);

  return (
    <PublicPageLayout isTitleVisible={false} title={contentPage.title}>
      <div className="c-docs-content-page__title">
        <div />
        <h1>{contentPage.title}</h1>
      </div>
      <button className="flex md:none bg-transparent border-none pointer p-0" onClick={toggleSideMenu}>
        <span className="sr-only">Open documentation menu</span>
        <IconMenuLeft />
      </button>
      <SideBar additionalClassNames="p-4" isSideBarOpen={isSideMenuOpen} isCloseButtonVisible onClose={onSideMenuClose}>
        <nav aria-label="Documentation mobile menu">
          <ul className="c-docs-content-page__list">
            {contentGroups.map((group) => (
              <li key={group.id} className="mb-6">
                <span className="block h4 weight-600 mb-4">{group.title}</span>
                <ul className="list-none p-0">
                  {group.contentPages.map((page) => {
                    const isCurrentPage = pageUrl === page.url;

                    return (
                      <li key={page.id}>
                        <NextLink href={`/docs/${page.url}`}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            className={`block mb-3 text-sm transition hover:color-primary ${
                              isCurrentPage ? 'color-primary weight-600' : 'color-black'
                            }`}
                            onClick={onSideMenuClose}
                          >
                            {page.title}
                          </a>
                        </NextLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        </nav>
      </SideBar>
      <div className="flex">
        <nav aria-label="Documentation menu" className="none md:flex mr-12">
          <ul className="list-none p-0">
            {contentGroups.map((group) => (
              <li key={group.id} className="mb-4">
                <span className="block h4 weight-600 mb-2">{group.title}</span>
                <ul className="list-none p-0 -ml-2">
                  {group.contentPages.map((page) => {
                    const isCurrentPage = pageUrl === page.url;
                    return (
                      page.status === 'PUBLISHED' && (
                        <li key={page.id} className="ml-0">
                          <NextLink href={`/docs/${page.url}`}>
                            <a
                              className={`block p-2 text-sm rounded transition hover:color-primary ${
                                isCurrentPage ? 'bg-primary-opacity color-primary weight-600' : 'color-black'
                              }`}
                            >
                              {page.title}
                            </a>
                          </NextLink>
                        </li>
                      )
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        </nav>

        <div className="flex-1">
          <CMSContentPage contentPage={contentPage} />

          <div className="flex items-center px-4">
            {previousPage && <PageUrlLink title={String(previousPage.title)} url={String(previousPage.url)} isPrevious />}
            {nextPage && <PageUrlLink title={String(nextPage.title)} url={String(nextPage.url)} />}
          </div>
        </div>
      </div>
    </PublicPageLayout>
  );
};
