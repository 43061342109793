import { IProjectFormFields } from './ProjectForm.interfaces';

const PROJECT_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-project-form',
});

const PROJECT_FORM_DEFAULTS = Object.freeze({
  heading: 'Project',
});

const PROJECT_FORM_INITIAL_VALUES: IProjectFormFields = {
  name: '',
};

export { PROJECT_FORM_BLOCK_CSS_IDENTIFIERS, PROJECT_FORM_DEFAULTS, PROJECT_FORM_INITIAL_VALUES };
