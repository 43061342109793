import { ChangeEvent, useEffect, useState } from 'react';

import { Checkbox, Label } from '@netfront/ui-library';

import { HighlightListItem } from '../HighlightListItem';

import { LANGUAGES } from './ProjectLanguages.constants';
import { ProjectLanguagesProps } from './ProjectLanguages.interfaces';

import { ProjectConfigurationLanguageType } from '../../interfaces';

const ProjectLanguages = ({ languages, onChange }: ProjectLanguagesProps) => {
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const onLanguageToggle = (event: ChangeEvent) => {
    const { checked: isChecked, id } = event.target as HTMLInputElement;
    const hasLanguage = languages.includes(id as ProjectConfigurationLanguageType);

    if (isChecked && !hasLanguage) {
      onChange([...languages, id] as ProjectConfigurationLanguageType[]);
    }

    if (!isChecked && hasLanguage) {
      onChange(languages.filter((language) => language !== id));
    }
  };

  const onSelectAllToggle = (event: ChangeEvent) => {
    const { checked: isChecked } = event.target as HTMLInputElement;
    onChange(isChecked ? (Object.keys(LANGUAGES) as ProjectConfigurationLanguageType[]) : []);
  };

  useEffect(() => {
    setIsAllSelected(languages.length === Object.keys(LANGUAGES).length);
  }, [languages]);

  return (
    <>
      <div className="c-project-languages__header">
        <h2 className="h5 font-body mb-0 flex-1 py-2">Languages</h2>
        <span className="c-project-languages__counter">
          {languages.length} out of {Object.keys(LANGUAGES).length}
        </span>
        <Checkbox
          id="select-all"
          isChecked={isAllSelected}
          labelText={`${isAllSelected ? 'Deselect' : 'Select'} all languages`}
          name="select-all"
          value="select-all"
          isLabelHidden
          onChange={onSelectAllToggle}
        />
      </div>
      <ul className="c-project-languages__list">
        {Object.keys(LANGUAGES).map((language) => {
          const isChecked = languages.includes(language as ProjectConfigurationLanguageType);
          const languageLabel = LANGUAGES[language] as string;

          return (
            <HighlightListItem key={`language-${languageLabel.toLowerCase()}`} isHighlighted={isChecked}>
              <Label
                additionalClassNames="c-project-languages__label"
                forId={language.toLowerCase()}
                labelText={languageLabel.toLowerCase()}
              />
              <Checkbox
                id={language}
                isChecked={isChecked}
                labelText={language.toLowerCase()}
                name={language}
                value={language}
                isLabelHidden
                onChange={onLanguageToggle}
              />
            </HighlightListItem>
          );
        })}
      </ul>
    </>
  );
};

export { ProjectLanguages };
