import { Button } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { PlanUsageProps } from './PlanUsage.interfaces';

const PlanUsage = ({ plans }: PlanUsageProps) => {
  const { query } = useRouter();
  const { organisationKey, projectId } = query;

  return (
    <div className="c-plan-usage-container">
      <div className="c-plan-usage">
        {plans.map(({ color, label, percentage, usage }) => (
          <div key={`plan-usage-${label}`} className="c-plan-usage__details">
            <CircularProgressbar
              className="c-progress-circle"
              strokeWidth={18}
              styles={buildStyles({
                pathColor: color,
                trailColor: 'var( --g-color-grey-100)',
              })}
              value={percentage}
            />
            <div className="c-plan-usage__item">
              <strong className="c-plan-usage__label">{label}</strong>
              <span className="c-plan-usage__metrics">{usage}</span>
            </div>
          </div>
        ))}
      </div>

      <Button
        additionalClassNames="c-plan-usage__link"
        linkButton={{
          id: 'update',
          url: `/dashboard/${String(organisationKey)}/${String(projectId)}/payment`,
        }}
        text="Change plan"
        variant="cta"
      />

    </div>
  );
};

export { PlanUsage };
