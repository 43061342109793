import React from 'react';

import { getBEMClassName, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS } from '@netfront/gelada-identity-library';
import { Button, Input } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';

import { PROJECT_FORM_INITIAL_VALUES, PROJECT_FORM_BLOCK_CSS_IDENTIFIERS } from './ProjectForm.constants';
import { IProjectFormFields, ProjectFormProps } from './ProjectForm.interfaces';

const ProjectForm = ({ buttonText = 'Add', isSubmitting, onAdd }: ProjectFormProps) => {
  const { form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const { form: formBlockCssId } = PROJECT_FORM_BLOCK_CSS_IDENTIFIERS;

  const { control, handleSubmit, reset } = useForm<IProjectFormFields>({
    defaultValues: PROJECT_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <>
      <form
        className={getBEMClassName(formBlockCssId, formElementCssId)}
        onSubmit={handleSubmit(({ name }: IProjectFormFields) => {
          onAdd({
            name,
          });

          reset();
        })}
      >
        <div className="c-form__header" />
        <div className="c-form__body">
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(formBlockCssId, 'name')}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="name"
                isDisabled={isSubmitting}
                labelText="Name"
                placeholder=""
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </div>
        <div className="c-form__footer">
          <Button isDisabled={isSubmitting} text={buttonText} type="submit" />
        </div>
      </form>
    </>
  );
};

export { ProjectForm };
