import React from 'react';

import { getBEMClassName, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS } from '@netfront/gelada-identity-library';
import { Button, Input } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';

import { UPDATE_ORGANISATION_FORM_BLOCK_CSS_IDENTIFIERS } from './UpdateOrganisationForm.constants';
import { UpdateOrganisationFormProps, IUpdateOrganisationFormFields } from './UpdateOrganisationForm.interfaces';

const UpdateOrganisationForm = ({ buttonText = 'Update', isSubmitting, onUpdate, defaultName }: UpdateOrganisationFormProps) => {
  const { form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const { form: formBlockCssId } = UPDATE_ORGANISATION_FORM_BLOCK_CSS_IDENTIFIERS;

  const { control, handleSubmit, reset } = useForm<IUpdateOrganisationFormFields>({
    defaultValues: {
      name: defaultName ?? '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <>
      <form
        className={getBEMClassName(formBlockCssId, formElementCssId)}
        onSubmit={
          handleSubmit(({ name }: IUpdateOrganisationFormFields) => {
            onUpdate({
              name,
            });

            reset();
          })
        }
      >
        <div className="c-form__header" />
        <div className="c-form__body">
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(formBlockCssId, 'name')}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="name"
                isDisabled={isSubmitting}
                labelText="Name"
                placeholder=""
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </div>
        <div className="c-form__footer">
          <Button isDisabled={isSubmitting} text={buttonText} type="submit" />
        </div>
      </form>
    </>
  );
};

export { UpdateOrganisationForm };
