/* eslint-disable @typescript-eslint/indent */
import { useEffect, useState } from 'react';

import { useCookie, useToggle } from '@netfront/common-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  IGeladaOrganisation,
  saveAuthenticationData,
  useCreateGeladaOrganisation,
  useDomain,
  useGetGeladaOrganisationsForConnectedUser,
  useGetLoggedUser,
  useProtectedRoute,
  useRefreshToken,
  useUpdateGeladaOrganisation,
} from '@netfront/gelada-identity-library';
import { AddNewButtonCard, CardListPageTemplate, CoverLink, NavigationCard, SettingsButton, SideBar, TabSet } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { PageLayout, OrganisationForm, UpdateOrganisationForm } from '../../../components';
import { useToast } from '../../../hooks';

const DashboardPage = () => {
  const { isSecureCookie } = useCookie();
  const { getDomain } = useDomain();
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    push,
    reload,
    query: { shouldRedirect },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();
  const { isToggled: isEditSideBarOpen, toggle: toggleIsEditSideBarOpen } = useToggle();
  const { isToggled: isSideBarOpen, toggle: toggleIsSideBarOpen } = useToggle();

  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [geladaOrganisations, setGeladaOrganisations] = useState<IGeladaOrganisation[]>([]);
  const [selectedGeladaOrganisation, setSelectedGeladaOrganisation] = useState<IGeladaOrganisation | null>(null);

  const handleGeladaOrganisationClick = (id: string) => {
    push({
      pathname: `/dashboard/${id}`,
      query: { shouldRedirect: true },
    }).catch((error) => {
      handleToastError({
        error,
      });
    });
  };

  const { handleCreateGeladaOrganisation, isLoading: isCreateGeladaOrganisationLoading = false } = useCreateGeladaOrganisation({
    onCompleted: ({ organisation }) => {
      setGeladaOrganisations([...geladaOrganisations, organisation]);

      toggleIsSideBarOpen();

      handleRefreshToken();
      handleToastSuccess({
        message: `${organisation.name} created`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetLoggedUser } = useGetLoggedUser({
    onCompleted: ({ user }) => {
      const { memberships } = user;
      if (!memberships.length) {
        push('/welcome/get-started').catch((error) => {
          handleToastError({
            error,
          });
        });
      }
      const projectMembership = memberships.find((r) => r.type === 'PROJECT');
      if (projectMembership) {
        push(`/dashboard/${projectMembership.organisation.key}/${String(projectMembership.project?.guid)}`).catch((error) => {
          handleToastError({
            error,
          });
        });
      } else {
        push('/welcome/get-started').catch((error) => {
          handleToastError({
            error,
          });
        });
      }
    },
  });

  const { handleGetGeladaOrganisationsForConnectedUser, isLoading: isGetGeladaOrganisationsForConnectedUserLoading = false } =
    useGetGeladaOrganisationsForConnectedUser({
      onCompleted: ({ geladaOrganisations: returnedGeladaOrganisations }) => {
        if (!returnedGeladaOrganisations.length) {
          void handleGetLoggedUser({ shouldIncludeUserMembershipsProject: true });
          return;
        }

        if (returnedGeladaOrganisations.length === 1 && shouldRedirect) {
          const [firstGeladaOrganisation] = returnedGeladaOrganisations;

          handleGeladaOrganisationClick(firstGeladaOrganisation.key);

          return;
        }

        setGeladaOrganisations(returnedGeladaOrganisations);
        setHasRequiredPageData(true);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleRefreshToken, isLoading: isRefreshTokenLoading = false } = useRefreshToken({
    onCompleted: ({ accessToken, refreshToken, user }) => {
      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      saveAuthenticationData({
        accessToken,
        accessTokenOptionalCookieAttributes: {
          domain,
          secure: isSecure,
        },
        accessTokenStorageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
        refreshToken,
        refreshTokenOptionalCookieAttributes: {
          domain,
          secure: isSecure,
        },
        refreshTokenStorageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
        user,
        userOptionalCookieAttributes: {
          domain,
          secure: isSecure,
        },
        userStorageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
      });

      reload();
    },
  });

  const { handleUpdateGeladaOrganisation, isLoading: isUpdateGeladaOrganisationLoading = false } = useUpdateGeladaOrganisation({
    onCompleted: ({ organisation: geladaOrganisation }) => {
      const updateGeladaOrganisations = geladaOrganisations.map((organisation) => {
        const { id: organisationId } = organisation;
        const { id: geladaOrganisationId, name: geladaOrganisationName } = geladaOrganisation;

        return organisationId === geladaOrganisationId
          ? {
              ...organisation,
              name: geladaOrganisationName,
            }
          : organisation;
      });

      setGeladaOrganisations(updateGeladaOrganisations);
      toggleIsEditSideBarOpen();

      handleToastSuccess({
        message: `${geladaOrganisation.name} updated`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetGeladaOrganisationsForConnectedUser();
    localStorage.removeItem('projectId');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (isEditSideBarOpen) {
      return;
    }

    setSelectedGeladaOrganisation(null);
  }, [isEditSideBarOpen]);

  const isLoading =
    isCreateGeladaOrganisationLoading ||
    isGetGeladaOrganisationsForConnectedUserLoading ||
    isRefreshTokenLoading ||
    isUpdateGeladaOrganisationLoading;

  return (
    <PageLayout isPreloaderVisible={isLoading} title="Dashboard" hasPrivateLayout>
      <div className="c-container">
        {hasRequiredPageData && (
          <>
            <CardListPageTemplate
              addNewButtonCard={<AddNewButtonCard onClick={toggleIsSideBarOpen} />}
              breadcrumbItems={[]}
              informationBoxMessage="Create and manage groups to organise your projects"
              items={geladaOrganisations.map((organisation) => (
                <NavigationCard
                  key={organisation.id}
                  coverLink={<CoverLink href={`/dashboard/${String(organisation.key)}`} supportiveText={organisation.name} />}
                  settingsButton={
                    <SettingsButton
                      supportiveText="Edit organisation"
                      onClick={() => {
                        toggleIsEditSideBarOpen();
                        setSelectedGeladaOrganisation(organisation);
                      }}
                    />
                  }
                  title={organisation.name}
                />
              ))}
              pageTitle="Dashboard"
            />

            <SideBar isSideBarOpen={isSideBarOpen} isCloseButtonVisible onClose={toggleIsSideBarOpen}>
              <TabSet
                defaultActiveTabId="id_add_organisation_general_tab"
                tabs={[
                  {
                    id: 'id_add_organisation_general_tab',
                    label: 'Organisation',
                    view: () => (
                      <OrganisationForm
                        isSubmitting={isCreateGeladaOrganisationLoading}
                        onCreate={async ({ name }) => {
                          await handleCreateGeladaOrganisation({
                            description: '',
                            name,
                            key: name.toKebabCase(),
                          });
                        }}
                      />
                    ),
                    iconId: 'id_general_tab_icon',
                  },
                ]}
              />
            </SideBar>
            <SideBar isSideBarOpen={isEditSideBarOpen} isCloseButtonVisible onClose={toggleIsEditSideBarOpen}>
              {selectedGeladaOrganisation && (
                <TabSet
                  defaultActiveTabId="id_edit_organistation_general_tab"
                  tabs={[
                    {
                      id: 'id_edit_organistation_general_tab',
                      label: 'Organisation',
                      view: () => (
                        <UpdateOrganisationForm
                          defaultName={selectedGeladaOrganisation.name}
                          isSubmitting={isUpdateGeladaOrganisationLoading}
                          onUpdate={async ({ name }) => {
                            await handleUpdateGeladaOrganisation({
                              name,
                              description: selectedGeladaOrganisation.description,
                              id: selectedGeladaOrganisation.id,
                              tag: selectedGeladaOrganisation.tag,
                              url: selectedGeladaOrganisation.url,
                            });
                          }}
                        />
                      ),
                      iconId: 'id_general_tab_icon',
                    },
                  ]}
                />
              )}
            </SideBar>
          </>
        )}
      </div>
    </PageLayout>
  );
};

export { DashboardPage };
