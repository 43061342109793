import { ILeftSidebarLinks } from './LeftSidebarLinks.interfaces';

const LEFT_SIDEBAR_LIST: ILeftSidebarLinks[] = [
  {
    className: 'c-spotlight__settings',
    iconId: 'id_settings_icon',
    name: 'Dashboard',
    value: 'dashboard',
  },
  {
    className: 'c-spotlight__style-layout',
    iconId: 'id_style_tab_icon',
    name: 'Style and layout',
    value: 'style',
  },
  {
    className: 'c-spotlight__integrations',
    iconId: 'id_key_tab_icon',
    name: 'Install codes',
    value: 'integrations',
  },
  {
    className: 'c-spotlight__reporting',
    iconId: 'id_units_tab_icon',
    name: 'Reporting',
    value: 'reporting',
  },
  {
    href: '/contact',
    iconId: 'id_support_icon',
    name: 'Support',
    value: 'support',
  },
  {
    href: '/docs',
    iconId: 'id_resources_icon',
    name: 'Resources',
    value: 'resources',
  },
  {
    iconId: 'id_team_icon',
    name: 'Team',
    value: 'team',
  },
];

const LEFT_SIDEBAR_LIST_READ_ONLY: ILeftSidebarLinks[] = [
  {
    iconId: 'id_dashboard_icon',
    name: 'Dashboard',
    value: 'dashboard',
  },
  {
    iconId: 'id_units_tab_icon',
    name: 'Reporting',
    value: 'reporting',
  },
];

export { LEFT_SIDEBAR_LIST, LEFT_SIDEBAR_LIST_READ_ONLY };
