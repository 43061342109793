import React, { useState, useEffect, useRef } from 'react';

import { useCookie, useGeladaAccessTokenDecoder } from '@netfront/common-library';
import { useToast } from '@netfront/ekardo-content-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTION,
  useDomain,
  useGetGeladaProject,
  useIdentitySiteUrls,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import {
  AlphaConsoleSideMenu,
  AvatarBreadcrumbSectionTemplate,
  Button,
  InformationBox,
  LinkButton,
  SideBar,
  Table,
  TabSet,
} from '@netfront/ui-library';
import noop from 'lodash.noop';
import { useRouter } from 'next/router';

import { INTEGRATION_PAGE_CONSTANTS } from './IntegrationsPage.constants';
import { getApiKeyTableData } from './IntegrationsPage.helpers';
import { IApiKeyTableData } from './IntegrationsPage.interfaces';

import { useApiKeyContext } from '../../../contexts';
import { useCreateApiKey, useGetApiKeysByProject, useGetSideMenu, useGetProject, useLastProjectVisited } from '../../../hooks';
import { IDBProject } from '../../../interfaces';
import { BUTTON_CLASSES } from '../../LinkButton';
import { PageLayout } from '../../PageLayout';
import { getPlanUsagePercentage, getPlanUsageText, PlanUsage } from '../../PlanUsage';

const { pageTitle, sideBarTabs, tableColumns } = INTEGRATION_PAGE_CONSTANTS;

const IntegrationsPage = () => {
  const { dispatch, state: apiKeys = [] } = useApiKeyContext();
  const { createLastProjectVisitedCookie, getAccessTokenCookie, isSecureCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getDecodedJwt, getJwtUserId } = useGeladaAccessTokenDecoder();
  const { leftSideBarLinks, topSideMenuLink } = useGetSideMenu();
  const { getLastProjectVisitedPath } = useLastProjectVisited();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    query: { organisationKey, projectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const guidRef = useRef<HTMLSpanElement | null>(null);

  const [apiKeyTableData, setApiKeyTableData] = useState<IApiKeyTableData[]>();
  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [kanziProject, setKanziProject] = useState<IDBProject>();
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>();
  const [userId, setUserId] = useState<number>();

  const accessToken = getAccessTokenCookie();

  const handleCreateApiKeyClick = () => {
    handleCreateApiKey({
      projectId: String(projectId),
      title: `API key ${apiKeys.length + 1}`,
      type: 'WEB',
    });
  };

  const handleSettingsButtonClick = (apiKeyId: number) => {
    dispatch({
      payload: {
        apiKeyId,
      },
      type: 'setSelectedApiKeyId',
    });

    setIsSideBarOpen(true);
  };

  const handleSideBarOpenClose = () => {
    setIsSideBarOpen((currentValue) => !currentValue);
  };

  const { handleGetProject } = useGetProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ project }) => {
      setKanziProject(project);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreateApiKey, isLoading: isCreateApiKeyLoading = false } = useCreateApiKey({
    onCompleted: ({ apiKey }) => {
      const updatedApiKeys = [...apiKeys, apiKey];

      dispatch({
        payload: {
          apiKeys: updatedApiKeys,
        },
        type: 'saveApiKeys',
      });

      handleSettingsButtonClick(apiKey.id);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetApiKeysByProject, isLoading: isGetApiKeysByProjectLoading = false } = useGetApiKeysByProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ apiKeys: returnedApiKeys }) => {
      dispatch({
        payload: {
          apiKeys: returnedApiKeys,
        },
        type: 'saveApiKeys',
      });

      setHasRequiredPageData(true);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetGeladaProject, isLoading: isGetProjectLoading = false } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject: { name } }) => {
      setProjectName(name);

      handleGetApiKeysByProject({
        projectId: String(projectId),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const decodedJwt = getDecodedJwt(accessToken);

    setUserId(getJwtUserId(decodedJwt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!(isAuthenticated && isDomainReady && organisationKey && projectId && userId)) {
      return;
    }

    setIdentityUrl(getBaseUrl());

    createLastProjectVisitedCookie({
      optionalCookieAttributesInput: {
        domain: getDomain(),
        secure: isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE),
        storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
      },
      value: getLastProjectVisitedPath(organisationKey, projectId, userId),
    });

    handleGetProject({
      id: String(projectId),
    });

    handleGetGeladaProject({
      projectId: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady, organisationKey, projectId, userId]);

  useEffect(() => {
    if (!apiKeys.length) {
      return;
    }

    setApiKeyTableData(
      getApiKeyTableData({
        apiKeys,
        onSettingsButtonClick: handleSettingsButtonClick,
        guidRef,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeys]);

  const isLoading = isCreateApiKeyLoading || isGetApiKeysByProjectLoading || isGetProjectLoading;

  return (
    <PageLayout isPreloaderVisible={isLoading} title={pageTitle} hasPrivateLayout>
      {hasRequiredPageData && (
        <div className="c-page-wrapper">
          <div className="c-page__content">
            {kanziProject && (
              <PlanUsage
                plans={[
                  {
                    color: 'var(--g-color-red-400)',
                    label: 'Translate usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                    usage: getPlanUsageText(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                  },
                  {
                    color: 'var(--g-color-orange-200)',
                    label: 'Speech usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                    usage: getPlanUsageText(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                  },
                  {
                    color: 'var(--g-color-blue-200)',
                    label: 'PDF usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedPdf, kanziProject.remainingPdf),
                    usage: getPlanUsageText(kanziProject.allowedPdf, kanziProject.remainingPdf),
                  },
                ]}
              />
            )}
            <div className="flex flex-col mb-8 lg:flex-row lg:items-center">
              {projectName ? (
                <AvatarBreadcrumbSectionTemplate
                  additionalClassNames="flex-1 mb-8 md:mb-0"
                  breadcrumbItems={[
                    {
                      key: '0',
                      content: (
                        <LinkButton
                          additionalClassNames={`${BUTTON_CLASSES['subtle-link']} color-black`}
                          text="Dashboard"
                          url={`${identityUrl}/dashboard`}
                        />
                      ),
                    },
                    {
                      key: '1',
                      content: <span>{pageTitle}</span>,
                    },
                  ]}
                  title={projectName}
                />
              ) : null}
            </div>

            <InformationBox
              additionalClassNames="mb-8"
              iconId="id_info_icon"
              message="Create and manage your project integrations, multiple keys can be created for the same project and used across different locations"
            />

            <div className="flex flex-1 flex-wrap -mx-4">
              <div className="w-full px-4">
                <div className="flex items-center">
                  <h3 className="h4 font-body flex-1 weight-400">{pageTitle}</h3>
                  {projectId && <Button text="Create key" onClick={handleCreateApiKeyClick} />}
                </div>
                <div className="mt-8">
                  {apiKeyTableData ? (
                    <>
                      {!apiKeyTableData.length && (
                        <>
                          No API keys have been created yet. Click on the <span className="weight-600">Create key</span> button to create
                          one!
                        </>
                      )}
                      {Boolean(apiKeyTableData.length) && <Table<IApiKeyTableData> columns={tableColumns} data={apiKeyTableData} />}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <SideBar isSideBarOpen={isSideBarOpen} isCloseButtonVisible onClose={handleSideBarOpenClose}>
            {/* Note: This conditional render is needed so the TabSet component gets rerendered to have the first tab selected */}
            {isSideBarOpen && <TabSet defaultActiveTabId={sideBarTabs[0].id} tabs={sideBarTabs} />}
          </SideBar>
        </div>
      )}

      {!isLoading && <AlphaConsoleSideMenu items={leftSideBarLinks} topLink={topSideMenuLink} onClose={noop} />}
    </PageLayout>
  );
};

export { IntegrationsPage };
