import { useEffect, useState } from 'react';

import { useCookie } from '@netfront/common-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  IGeladaOrganisation,
  IGeladaProject,
  saveAuthenticationData,
  useCreateGeladaProject,
  useDomain,
  useGetGeladaOrganisationByKey,
  useGetGeladaProjects,
  useIdentitySiteUrls,
  useProtectedRoute,
  useRefreshToken,
  useUpdateGeladaProject,
} from '@netfront/gelada-identity-library';
import { AddNewButtonCard, CardListPageTemplate, CoverLink, NavigationCard, SettingsButton, SideBar, TabSet } from '@netfront/ui-library';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { PageLayout, ProjectForm, UpdateProjectForm } from '../../../components';
import { useToast } from '../../../hooks';

const OrganisationProjectsPage = () => {
  const { isSecureCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    push,
    query: { organisationKey, shouldRedirect },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [geladaProjects, setGeladaProjects] = useState<IGeladaProject[]>([]);
  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isEditMenuOpen, setIsEditMenuOpen] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<IGeladaOrganisation>();
  const [selectedProject, setSelectedProject] = useState<IGeladaProject | null>(null);

  const toggleSideBar = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  const toggleEditSideBar = () => {
    setIsEditMenuOpen(!isEditMenuOpen);
  };

  const handleAddProject = async (name: string) => {
    if (!organisation) {
      return;
    }

    await handleCreateGeladaProject({
      name,
      key: name.toKebabCase(),
      isCustomBuild: false,
      organisationId: Number(organisation.id),
    });
  };

  const handleGeladaProjectClick = (id: string) => {
    push(`/dashboard/${String(organisationKey)}/${id}`).catch((error) => {
      handleToastError({
        error,
      });
    });
  };

  const { handleCreateGeladaProject, isLoading: isCreateProjectLoading = false } = useCreateGeladaProject({
    onCompleted: ({ project }) => {
      setGeladaProjects([...geladaProjects, project]);
      setIsSideBarOpen(false);

      void handleRefreshToken();

      handleToastSuccess({
        message: `${project.name} updated`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetGeladaOrganisationByKey, isLoading: isGetGeladaOrganisationByKeyLoading = false } = useGetGeladaOrganisationByKey({
    onCompleted: ({ geladaOrganisation }) => {
      setOrganisation(geladaOrganisation);

      void handleGetGeladaProjects({
        organisationKey: String(organisationKey),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetGeladaProjects, isLoading: isGetGeladaProjectsLoading = false } = useGetGeladaProjects({
    onCompleted: ({ geladaProjects: returnedGeladaProjects }) => {
      if (returnedGeladaProjects.length === 1 && shouldRedirect) {
        const [firstProject] = returnedGeladaProjects;

        handleGeladaProjectClick(firstProject.id);

        return;
      }

      setGeladaProjects(returnedGeladaProjects);
      setHasRequiredPageData(true);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });

      push(`${identityUrl}/dashboard`);
    },
  });

  const { handleRefreshToken } = useRefreshToken({
    onCompleted: ({ accessToken, refreshToken, user }) => {
      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      saveAuthenticationData({
        accessToken,
        accessTokenOptionalCookieAttributes: {
          domain,
          secure: isSecure,
        },
        accessTokenStorageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
        refreshToken,
        refreshTokenOptionalCookieAttributes: {
          domain,
          secure: isSecure,
        },
        refreshTokenStorageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
        user,
        userOptionalCookieAttributes: {
          domain,
          secure: isSecure,
        },
        userStorageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
      });
    },
  });

  const { handleUpdateGeladaProject, isLoading: isUpdateProjectLoading = false } = useUpdateGeladaProject({
    onCompleted: ({ project: geladaProject }) => {
      const updateProjects = geladaProjects.map((project) => {
        if (project.id === geladaProject.id) {
          return { ...project, name: geladaProject.name };
        }

        return project;
      });

      setGeladaProjects(updateProjects);
      setIsEditMenuOpen(false);

      handleToastSuccess({
        message: `${geladaProject.name} updated`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!(isAuthenticated && organisationKey)) {
      return;
    }

    handleGetGeladaOrganisationByKey({
      organisationKey: String(organisationKey),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, organisationKey]);

  useEffect(() => {
    if (!isEditMenuOpen) {
      setSelectedProject(null);
    }
  }, [isEditMenuOpen]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(getBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const isLoading = /*isGetProjectsForConnectedUserLoading ||*/ isGetGeladaProjectsLoading || isGetGeladaOrganisationByKeyLoading;

  return (
    <PageLayout isPreloaderVisible={isLoading} title="Organisations" hasPrivateLayout>
      <div className="c-container">
        {hasRequiredPageData && (
          <>
            <CardListPageTemplate
              addNewButtonCard={<AddNewButtonCard onClick={toggleSideBar} />}
              breadcrumbItems={[{ content: <Link href={`${identityUrl}/dashboard`}>Dashboard</Link>, key: 'Dashboard' }]}
              informationBoxMessage="Manage your projects"
              items={geladaProjects.map((project) => (
                <NavigationCard
                  key={project.id}
                  coverLink={<CoverLink href={`/dashboard/${String(organisationKey)}/${project.id}`} supportiveText={project.name} />}
                  settingsButton={
                    <SettingsButton
                      supportiveText="Edit project"
                      onClick={() => {
                        toggleEditSideBar();
                        setSelectedProject(project);
                      }}
                    />
                  }
                  title={project.name}
                />
              ))}
              pageTitle={String(organisation?.name) || 'Projects'}
            />
            <SideBar isSideBarOpen={isSideBarOpen} isCloseButtonVisible onClose={toggleSideBar}>
              <TabSet
                defaultActiveTabId="id_add_project_general_tab"
                tabs={[
                  {
                    id: 'id_add_project_general_tab',
                    label: 'Project',
                    view: () => <ProjectForm isSubmitting={isCreateProjectLoading} onAdd={({ name }) => handleAddProject(name)} />,
                    iconId: 'id_general_tab_icon',
                  },
                ]}
              />
            </SideBar>
            <SideBar isSideBarOpen={isEditMenuOpen} isCloseButtonVisible onClose={toggleEditSideBar}>
              {selectedProject && (
                <TabSet
                  defaultActiveTabId="id_edit_project_general_tab"
                  tabs={[
                    {
                      id: 'id_edit_project_general_tab',
                      label: 'Project',
                      view: () => (
                        <UpdateProjectForm
                          defaultName={selectedProject.name}
                          isSubmitting={isUpdateProjectLoading}
                          onUpdate={async ({ name }) => {
                            await handleUpdateGeladaProject({
                              name,
                              description: String(selectedProject.description),
                              projectId: selectedProject.id,
                              url: String(selectedProject.url),
                            });
                          }}
                        />
                      ),
                      iconId: 'id_general_tab_icon',
                    },
                  ]}
                />
              )}
            </SideBar>
          </>
        )}
      </div>
    </PageLayout>
  );
};

export { OrganisationProjectsPage };
