import { useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Avatar, Dropdown, IDropdownNavigationItems, UserNavigation } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { useIsMounted } from '../../hooks';

const UserAccountMenu = () => {

  const { isMounted } = useIsMounted();

  const {
    query: { projectId, organisationKey },
  } = useRouter();


  const { getUser } = useUser();
  const loggedUser = getUser();

  const { isDomainReady } = useDomain();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();
  const [navigationList, setNavigationList] = useState<IDropdownNavigationItems[]>([]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const navigationListItemsOrg = [
      {
        name: `${String(loggedUser?.firstName)} ${String(loggedUser?.lastName)}`,
      },
      {
        href: String(profileUrl),
        name: 'Profile',
      },
      {
        href: `/docs`,
        name: 'Help docs',
      },
      {
        href: `https://calendly.com/greg_stephenson`,
        name: 'Book demo',
        target: '_blank',
      },
      {
        href: logoutUrl,
        name: 'Logout',
      },
    ];

    if (!projectId) {
      setNavigationList(navigationListItemsOrg);

      return;
    }

    const navigationListItems = [
      {
        name: `${String(loggedUser?.firstName)} ${String(loggedUser?.lastName)}`,
      },
      {
        href: String(profileUrl),
        name: 'Profile',
      },
      {
        href: `/dashboard/${String(organisationKey)}/${String(projectId)}/payments`,
        name: 'Manage plan',
      },
      {
        href: `/docs`,
        name: 'Help docs',
      },
      {
        href: `https://calendly.com/greg_stephenson`,
        name: 'Book demo',
        target: '_blank',
      },
      {
        href: logoutUrl,
        name: 'Logout',
      },
    ];

    setNavigationList(navigationListItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationKey, projectId, profileUrl, logoutUrl]);

  return (
    <div className="c-user-account-menu">
      {isMounted && loggedUser && (
        <Dropdown
          dropdownId="profile-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<Avatar size="small" title={`${String(loggedUser.firstName)} ${String(loggedUser.lastName)}`} />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <UserNavigation userNavigationListItems={navigationList} />
        </Dropdown>
      )}
    </div>
  );
};

export { UserAccountMenu };
