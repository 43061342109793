import { useEffect, useState } from 'react';

import { COMMON_LIBRARY_CONSTANTS, useCookie, useGeladaAccessTokenDecoder } from '@netfront/common-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTION,
  useDomain,
  useGetGeladaProject,
  useIdentitySiteUrls,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { AlphaConsoleSideMenu, AvatarBreadcrumbSectionTemplate, Button, InformationBox, LinkButton } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import noop from 'lodash.noop';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { CallBackProps } from 'react-joyride';

import { PROJECT_SETTINGS_PAGE_CONSTANTS } from './ProjectSettingsPage.constants';

import { getPlanUsagePercentage, getPlanUsageText, KanziPlugin, PlanUsage } from '../../../components';
import {
  useActivateProduct,
  useActivateProject,
  useGetProject,
  useGetProjectConfigurationByProject,
  useGetSideMenu,
  useLastProjectVisited,
  useToast,
  useUpdateProjectConfiguration,
} from '../../../hooks';
import { IDBProject, IProjectConfiguration, ProjectConfigurationLanguageType } from '../../../interfaces';
import { BUTTON_CLASSES } from '../../LinkButton';
import { PageLayout } from '../../PageLayout';
import { ProjectLanguages } from '../../ProjectLanguages';
import { IProjectSettingField, IProjectSettings, ProjectSettings } from '../../ProjectSettings';
import { Spotlight } from '../../Spotlight';
const { pageTitle, spotLightItems } = PROJECT_SETTINGS_PAGE_CONSTANTS;

const ProjectSettingsPage = () => {
  const { createLastProjectVisitedCookie, getAccessTokenCookie, isSecureCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getDecodedJwt, getJwtUserId } = useGeladaAccessTokenDecoder();
  const { leftSideBarLinks, topSideMenuLink } = useGetSideMenu();
  const { getLastProjectVisitedPath } = useLastProjectVisited();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    query: { organisationKey, projectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [kanziProject, setKanziProject] = useState<IDBProject>();
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isSaveChangesButtonDisabled, setIsSaveChangesButtonDisabled] = useState<boolean>(true);
  const [projectConfigurations, setProjectConfigurations] = useState<IProjectConfiguration>({} as IProjectConfiguration);
  const [projectConfigurationId, setProjectConfigurationId] = useState<number>();
  const [projectLanguages, setProjectLanguages] = useState([] as ProjectConfigurationLanguageType[]);
  const [projectSettings, setProjectSettings] = useState<IProjectSettings>();
  const [spotLightData, setSpotLightData] = useState<CallBackProps>();
  const [userId, setUserId] = useState<number>();

  const accessToken = getAccessTokenCookie();

  const handleProjectLanguagesChange = (languages: ProjectConfigurationLanguageType[]) => {
    setIsSaveChangesButtonDisabled(false);
    setProjectLanguages(languages);
  };

  const handleProjectSettingsChange = ({ field, value }: IProjectSettingField) => {
    setIsSaveChangesButtonDisabled(false);

    setProjectSettings({
      ...projectSettings,
      [field]: value,
    } as IProjectSettings);
  };

  const handleSaveButtonClick = () => {
    if (!projectConfigurationId) {
      return;
    }

    handleUpdateProjectConfiguration({
      id: projectConfigurationId,
      languages: projectLanguages,
      ...projectSettings,
    });
  };

  const { handleActivateProject, isLoading: isActivateProjectLoading = false } = useActivateProject({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        handleGetProjectConfigurationByProject({
          projectId: String(projectId),
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleActivateProduct } = useActivateProduct({});

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading = false } = useGetGeladaProject({
    onCompleted(data) {
      handleActivateProject({
        id: String(projectId),
        name: String(data.geladaProject.name),
      });

      void handleActivateProduct({
        projectId: String(projectId),
        name: String(data.geladaProject.name),
        organisationId: Number(data.geladaProject.organisationId),
      });
    },
  });
  const { handleGetProject, isLoading: isGetProjectLoading = false } = useGetProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ project }) => {
      setKanziProject(project);

      if (!isEmpty(project)) {
        if (project.isActive) {
          handleGetProjectConfigurationByProject({
            projectId: String(projectId),
          });

          return;
        }
      }

      handleGetGeladaProject({
        projectId: String(projectId),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetProjectConfigurationByProject, isLoading: isGetProjectConfigurationByProjectLoading = false } =
    useGetProjectConfigurationByProject({
      onCompleted: ({ projectConfiguration }) => {
        const { id, hasTranslate, hasSpeech, hasVolumeControl, hasPdf, isCollapse, isDraggable, languages, hasAccessibility } =
          projectConfiguration;

        setProjectConfigurations(projectConfiguration);
        setProjectConfigurationId(id);
        setProjectLanguages(languages);
        setProjectSettings({
          hasTranslate,
          hasSpeech,
          hasVolumeControl,
          hasPdf,
          isCollapse,
          isDraggable,
          hasAccessibility,
        });

        setHasRequiredPageData(true);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleUpdateProjectConfiguration, isLoading: isUpdateProjectConfigurationLoading = false } = useUpdateProjectConfiguration({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Updated',
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const decodedJwt = getDecodedJwt(accessToken);

    setUserId(getJwtUserId(decodedJwt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!(isAuthenticated && isDomainReady && organisationKey && projectId && userId)) {
      return;
    }

    const domain = getDomain();

    setIdentityUrl(getBaseUrl());

    createLastProjectVisitedCookie({
      optionalCookieAttributesInput: {
        domain,
        secure: isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE),
        storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
      },
      value: getLastProjectVisitedPath(organisationKey, projectId, userId),
    });

    handleGetProject({
      id: String(projectId),
    });

    localStorage.removeItem('projectId');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady, organisationKey, projectId, userId]);

  const isLoading =
    isActivateProjectLoading ||
    isGetProjectLoading ||
    isGetProjectConfigurationByProjectLoading ||
    isUpdateProjectConfigurationLoading ||
    isGetGeladaProjectLoading;

  const isDisableSpotLightClose = spotLightData?.step.title === 'Profile';

  return (
    <PageLayout geladaProject={kanziProject} isPreloaderVisible={isLoading} title={pageTitle} hasPrivateLayout>
      <Spotlight
        disableCloseOnEsc={true}
        disableOverlayClose={isDisableSpotLightClose}
        hideCloseButton={isDisableSpotLightClose}
        spotlightItem={spotLightItems}
        onSpotLightData={setSpotLightData}
      />

      {hasRequiredPageData && (
        <div className="c-page-wrapper">
          <div className="c-page__content">
            {kanziProject && (
              <PlanUsage
                plans={[
                  {
                    color: 'var(--g-color-red-400)',
                    label: 'Translate usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                    usage: getPlanUsageText(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                  },
                  {
                    color: 'var(--g-color-orange-200)',
                    label: 'Speech usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                    usage: getPlanUsageText(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                  },
                  {
                    color: 'var(--g-color-blue-200)',
                    label: 'PDF usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedPdf, kanziProject.remainingPdf),
                    usage: getPlanUsageText(kanziProject.allowedPdf, kanziProject.remainingPdf),
                  },
                ]}
              />
            )}

            <div className="flex lg:items-center flex-col lg:flex-row mb-8">
              {kanziProject?.name ? (
                <AvatarBreadcrumbSectionTemplate
                  additionalClassNames="flex-1 mb-8 md:mb-0"
                  breadcrumbItems={[
                    {
                      key: '0',
                      content: (
                        <LinkButton
                          additionalClassNames={`${BUTTON_CLASSES['subtle-link']} color-black`}
                          text="Dashboard"
                          url={`${identityUrl}/dashboard`}
                        />
                      ),
                    },
                    {
                      key: '1',
                      content: <span>{pageTitle}</span>,
                    },
                  ]}
                  title={kanziProject.name}
                />
              ) : null}
            </div>

            <InformationBox additionalClassNames="c-information-box__settings mb-8" iconId="id_info_icon">
              <span className="c-information-box__settings-text">
                Configure KANZI tool settings, layout and other cool features. If you need any help with configuration {` `}
                <NextLink href={`/docs`}>
                  <a className="c-information-box__link" rel="noopener noreferrer" target="_blank">
                    check out our docs
                  </a>
                </NextLink>
              </span>
            </InformationBox>

            <div className="flex flex-1 flex-wrap -mx-4 c-spotlight__settings">
              <div className="w-full lg:w-2/3 px-4 mb-4">
                {projectSettings ? (
                  <>
                    <ProjectSettings settings={projectSettings} onChange={handleProjectSettingsChange} />
                  </>
                ) : null}
              </div>

              <div className="c-project-languages w-full lg:w-1/3 px-4">
                <ProjectLanguages languages={projectLanguages} onChange={handleProjectLanguagesChange} />
              </div>
            </div>

            <div className="c-save-changes text-right">
              <Button isDisabled={isSaveChangesButtonDisabled} text="Save changes" variant="cta" onClick={handleSaveButtonClick} />
            </div>

            {projectSettings && (
              <KanziPlugin languages={projectLanguages} projectConfiguration={projectConfigurations} projectSettings={projectSettings} />
            )}
          </div>
        </div>
      )}

      {!isLoading && <AlphaConsoleSideMenu items={leftSideBarLinks} topLink={topSideMenuLink} onClose={noop} />}
    </PageLayout>
  );
};

export { ProjectSettingsPage };
