import { useEffect, useRef, useState, RefObject } from 'react';

import { useOutsideClick } from '@netfront/common-library';
import cx from 'classnames';

import { IconExpand, IconPdf, IconSpeech, IconTranslate, IconVolume } from 'components/Icons';

import { LANGUAGES } from '../ProjectLanguages/ProjectLanguages.constants';
import { IProjectSettings } from '../ProjectSettings';
import { IProjectStyleSettings } from '../StyleSettings';

import { IKanziPlugin } from './KanziPlugin.interfaces';

import { ProjectConfigurationLanguageType, ProjectConfigurationSizeType } from '../../interfaces';

const KanziPlugin = ({ languages: projectLanguages, projectConfiguration, projectSettings, styleSettings }: IKanziPlugin) => {
  const pluginRef = useRef() as RefObject<HTMLDivElement>;
  const { hasTranslate, hasSpeech, hasVolumeControl, hasPdf, languages, colour, cornerRadius, isCollapse, offset, position, theme } =
    projectConfiguration;

  const [isCollapseAll, setIsCollapseAll] = useState<boolean>(false);
  const [isDisplayLanguageList, setIsDisplayLanguageList] = useState<boolean>(false);
  const [kanziProjectSettings, setKanziProjectSettings] = useState<IProjectSettings>();
  const [kanziStyleSettings, setKanziStyleSettings] = useState<IProjectStyleSettings>();
  const [pluginBorderRadius, setPluginBorderRadius] = useState<string>('5px');
  const [pluginColor, setPluginColor] = useState<string>('');
  const [pluginLanguages, setPluginLanguages] = useState<ProjectConfigurationLanguageType[]>();
  const [pluginSize, setPluginSize] = useState<ProjectConfigurationSizeType>('MEDIUM');

  const handleToggleLanguageList = () => setIsDisplayLanguageList(!isDisplayLanguageList);

  useOutsideClick(pluginRef, () => setIsDisplayLanguageList(false));

  useEffect(() => {
    if (styleSettings) {
      setKanziStyleSettings(styleSettings);

      const { colour: color, cornerRadius: radius, isCollapse: isPluginCollapse, size } = styleSettings;

      setIsCollapseAll(isPluginCollapse);
      setPluginColor(color);
      setPluginBorderRadius(`${Number(radius) <= 0 ? 0 : Number(radius)}px`);
      setPluginLanguages(languages);
      setPluginSize(size);

      setKanziProjectSettings({
        isCollapse: isPluginCollapse,
        hasTranslate,
        hasSpeech,
        hasAccessibility: true,
        hasVolumeControl,
        hasPdf,
        isDraggable: true,
      });

      return;
    }

    if (projectSettings) {
      setKanziProjectSettings(projectSettings);

      const { colour: color, cornerRadius: radius, size } = projectConfiguration;
      const { isDraggable } = projectSettings;

      setPluginLanguages(projectLanguages);
      setIsCollapseAll(false);
      setPluginBorderRadius(`${Number(radius)}px`);
      setPluginColor(color);
      setPluginSize(size);

      setKanziStyleSettings({
        colour,
        cornerRadius,
        isCollapse,
        offset,
        position,
        size: pluginSize,
        theme,
      });

      if (!isDraggable) {
        setPluginColor('#cccfd9');
      }

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSettings, projectConfiguration, styleSettings, projectLanguages]);

  const iconThemeColor = kanziStyleSettings?.theme === 'LIGHT' ? '#41526e' : '#fff';

  const pluginSizes = {
    'c-kanzi-tool__list-item--large': pluginSize === 'LARGE',
    'c-kanzi-tool__list-item--medium': pluginSize === 'MEDIUM',
    'c-kanzi-tool__list-item--small': pluginSize === 'SMALL',
  };

  const iconButtonClasses = cx('c-kanzi-toolbar__action-button', {
    'c-kanzi-toolbar__action-button--dark-theme': kanziStyleSettings?.theme !== 'LIGHT',
    ...pluginSizes,
  });

  const kanziPluginClasses = cx('c-kanzi-drag c-kanzi-plugin__drag', {
    ...pluginSizes,
    'c-kanzi-plugin__drag--is-sticky': kanziStyleSettings?.isSticky,
  });

  return (
    <div className="c-kanzi-tool__container">
      <div className="c-kanzi">
        <div
          className={cx('c-kanzi-tool c-kanzi-animation', {
            ...pluginSizes,
          })}
        >
          <ul aria-label="Text to speech controls" className="c-kanzi-tool__toolbar" role="presentation">
            <div
              className={kanziPluginClasses}
              style={{
                backgroundColor: pluginColor,
                borderTopLeftRadius: pluginBorderRadius,
                borderBottomLeftRadius: pluginBorderRadius,
              }}
            />

            {isCollapseAll ||
              (kanziProjectSettings?.hasSpeech && (
                <li className="c-kanzi-tool__list-item">
                  <button className={iconButtonClasses} title="Play" type="button">
                    <IconSpeech color={iconThemeColor} />
                  </button>
                </li>
              ))}

            {isCollapseAll ||
              (kanziProjectSettings?.hasVolumeControl && (
                <li className="c-kanzi-tool__list-item">
                  <button aria-expanded="false" className={iconButtonClasses} title="Volume control" type="button">
                    <IconVolume color={iconThemeColor} />
                  </button>
                </li>
              ))}

            {isCollapseAll ||
              (kanziProjectSettings?.hasTranslate && (
                <div ref={pluginRef} className="c-kanzi-tool__translate c-kanzi-tool__list-item">
                  <button
                    className={cx('c-kanzi-plugin__language-button', iconButtonClasses)}
                    title="Translate control"
                    type="button"
                    onClick={handleToggleLanguageList}
                  >
                    <IconTranslate color={iconThemeColor} />
                  </button>
                  {isDisplayLanguageList && (
                    <ul className="c-kanzi-tool__language-menu">
                      {pluginLanguages?.length ? (
                        pluginLanguages.map((language) => (
                          <li key={language} className="kanzi-dropdown-item">
                            <button className="kanzi-language-available c-kanzi-plugin__language-button" type="button">
                              {LANGUAGES[language].toLocaleLowerCase().capitalize()}
                            </button>
                          </li>
                        ))
                      ) : (
                        <li className="kanzi-dropdown-item c-kanzi-tool__language-menu__no-text">No language selected</li>
                      )}
                    </ul>
                  )}
                </div>
              ))}

            {isCollapseAll ||
              (kanziProjectSettings?.hasPdf && (
                <li className="c-kanzi-tool__list-item">
                  <button className={iconButtonClasses} title="Download pdf" type="button">
                    <IconPdf color={iconThemeColor} />
                  </button>
                </li>
              ))}

            <li className="c-kanzi-tool__list-item">
              <div
                className={cx('c-kanzi-tool__logo-tag', {
                  ...pluginSizes,
                })}
              >
                <div className="c-kanzi-logo-tag__logo-block">
                  <a href="https://kanzi.io/" rel="noopener noreferrer" target="_blank">
                    <img alt="kanzi logo" height="12.8" src="https://plugin.kanzi.io/img/kanzi-tool-logo.svg" width="40" />
                  </a>
                </div>
              </div>
            </li>

            <li className="c-kanzi-tool__list-item">
              <button
                className={cx('c-kanzi-expand-block__button', {
                  ...pluginSizes,
                })}
                style={{
                  borderTopRightRadius: pluginBorderRadius,
                  borderBottomRightRadius: pluginBorderRadius,
                }}
                title="Expand"
                type="button"
                onClick={() => setIsCollapseAll(!isCollapseAll)}
              >
                <IconExpand
                  additionalClassNames={cx({
                    'c-kanzi-expand-block__button-icon--collapsed': isCollapseAll,
                  })}
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { KanziPlugin };
