import { CopyButton, SettingsButton } from '@netfront/ui-library';
import noop from 'lodash.noop';

import { IApiKeyTableData, IGetApiKeyTableDataParams } from './IntegrationsPage.interfaces';

const getApiKeyTableData = ({ apiKeys, onSettingsButtonClick, guidRef }: IGetApiKeyTableDataParams): IApiKeyTableData[] => {
  return apiKeys.map(({ creationDateLocaleDateString, guid, id, serializedRestrictions, status, title }) => {
    const tableData: IApiKeyTableData = {
      creationDateLocaleDateString,
      guid: (
        <div className="flex items-center">
          <span ref={guidRef}>{guid}</span>
          <CopyButton additionalClassNames="ml-4" copyElementRef={guidRef} supportiveText="Copy from span" onCopyCompleted={() => noop} />
        </div>
      ),
      serializedRestrictions,
      settingsButton: <SettingsButton supportiveText={`${guid} settings`} onClick={() => onSettingsButtonClick(id)} />,
      title: (
        <div className="flex items-center">
          <p className="mb-0 mr-4">{title}</p>
          <div className={`w-3 h-3 rounded-circle ${status === 'ACTIVATE' ? 'bg-active' : 'bg-pink-400'}`}>
            <span className="sr-only">{`Key is ${status === 'ACTIVATE' ? 'active' : 'inactive'}`}</span>
          </div>
        </div>
      ),
    };

    return tableData;
  });
};

export { getApiKeyTableData };
