import { IUpdateProjectFormFields } from './UpdateProjectForm.interfaces';

const UPDATE_PROJECT_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-project-form',
});

const UPDATE_PROJECT_FORM_DEFAULTS = Object.freeze({
  heading: 'Project',
});

const UPDATE_PROJECT_FORM_INITIAL_VALUES: IUpdateProjectFormFields = {
  name: '',
};

export { UPDATE_PROJECT_FORM_BLOCK_CSS_IDENTIFIERS, UPDATE_PROJECT_FORM_DEFAULTS, UPDATE_PROJECT_FORM_INITIAL_VALUES };
