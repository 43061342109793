import { useEffect, useState, useContext } from 'react';

import { useJwtDecoder, useCookie, IGeladaAccessTokenPayload } from '@netfront/common-library';
import { useIdentitySiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Dropdown, Icon, OrganisationNavigation } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { IOrganisationMenuItems } from './OrganisationMenu.interfaces';

import { CachingEntitiesContext } from '../../contexts';

const OrganisationMenu = () => {
  const { query } = useRouter();

  const { projectId, organisationKey } = query;

  const { isDomainReady } = useDomain();

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();

  const { project, organisation } = useContext(CachingEntitiesContext);

  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isCustomBuild, setIsCustomBuild] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<IOrganisationMenuItems[]>([]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(identityBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const navigationListItemsOrg = [
      {
        href: `${identityUrl}/account/${String(organisationKey)}/team`,
        name: 'Team',
        visible: true,
      },
      {
        href: `${identityUrl}/dashboard`,
        name: 'Switch',
        visible: !isCustomBuild,
      },
    ];

    const navigationListItems = [
      {
        href: `${identityUrl}/dashboard`,
        name: 'Projects',
        visible: true,
      },
      {
        href: `${identityUrl}/project/${String(projectId)}/user`,
        name: 'Team',
        visible: true,
      },
      {
        href: `${identityUrl}/dashboard`,
        name: 'Switch',
        visible: !isCustomBuild,
      },
    ];

    setNavigationList(projectId ? navigationListItems : navigationListItemsOrg);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityUrl, projectId, organisationKey]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return;
    }

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;

    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orgSmallText = projectId
    ? {
      smallText: organisation?.name,
      smallTextLink: `/dashboard/${String(organisationKey)}`,
    }
    : {};

  return (
    <div className="c-organisation-menu">
      <Dropdown
        dropdownId="organisation-dropdown"
        isDisplayContent={isSearchContentVisible}
        trigger={<Icon id="id_organisation_icon" />}
        onDisplayContent={setSearchIsContentVisible}
      >
        <OrganisationNavigation
          organisation={{
            name: projectId ? String(project?.name) : String(organisation?.name),
            url: !isCustomBuild ? `/dashboard/${String(organisationKey)}${projectId ? `/${String(projectId)}` : ''}` : '',
            dropdownNavigationItems: navigationList.filter((r) => r.visible),
          }}
          {...orgSmallText}
        />
      </Dropdown>
    </div>
  );
};

export { OrganisationMenu };
