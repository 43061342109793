/* eslint-disable react/jsx-no-undef */
import { useEffect, useRef, useState } from 'react';

import {
  IGeladaOrganisation,
  IGeladaProject,
  IGetGeladaOrganisationsForConnectedUserOnCompletedResponse,
  IGetGeladaProjectsOnCompletedResponse,
  useGetGeladaOrganisationsForConnectedUser,
  useGetGeladaProjects,
  useGetLoggedUser,
  IGetLoggedUserOnCompletedResponse,
  IUser,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { CopyButton, InformationBox, LinkButton, Skeleton } from '@netfront/ui-library';
import { maxBy } from 'lodash';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { COPY_CODE_SKELETON_COUNT } from './InstallInstructionsPage.constants';

import { BUTTON_CLASSES, InstructionsEmailForm, WelcomeStepProgress } from '../../../components';
import {
  IActivateProjectOnCompletedResponse,
  ICreateApiKeyOnCompletedResponse,
  IGetApiKeysByProjectOnCompletedResponse,
  useActivateProduct,
  useActivateProject,
  useCreateApiKey,
  useCreateRestriction,
  useGetApiKeysByProject,
  useToast,
} from '../../../hooks';
import { ApiKeyType } from '../../../interfaces';
import { PageLayout } from '../../PageLayout';
import { Tab, TabRound } from '../../Tabs';

const InstallInstructionsPage = () => {
  const PAGE_TITLE = 'Kanzi installation';

  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [apiKey, setApiKey] = useState<string>();
  const [project, setProject] = useState<IGeladaProject>();
  const [loggedUser, setLoggedUser] = useState<IUser>();

  const scriptRef = useRef<HTMLParagraphElement>(null);

  const handleCopyCompleted = (property: string) => {
    handleToastSuccess({
      message: `${property} copied to the clipboard`,
    });
  };

  const handleGetLoggedUserCompleted = (data?: IGetLoggedUserOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { user } = data;
    setLoggedUser(user);
  };

  const handleGetGeladaOrganisationsForConnectedUserCompleted = async (
    data?: IGetGeladaOrganisationsForConnectedUserOnCompletedResponse,
  ) => {
    if (!data) {
      return;
    }

    const { geladaOrganisations } = data;

    if (!geladaOrganisations.length) {
      push('/welcome/plugin-install-location').catch((error) => {
        handleToastError({
          error,
        });
      });

      return;
    }

    const { key: organisationKey } = maxBy(geladaOrganisations, 'id') as IGeladaOrganisation;

    await handleGetGeladaProjects({
      organisationKey,
    });
  };

  const handleGetGeladaProjectsCompleted = (data?: IGetGeladaProjectsOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { geladaProjects } = data;
    const currentProject = maxBy(geladaProjects, 'id');
    setProject(currentProject);

    void handleActivateProduct({
      projectId: String(currentProject?.id),
      name: String(currentProject?.name),
      organisationId: Number(currentProject?.organisationId),
    });

    handleActivateProject({
      id: String(currentProject?.id),
      name: String(currentProject?.name),
    });
  };

  const handleActivateProjectCompleted = (data?: IActivateProjectOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      handleGetApiKeysByProject({
        projectId: String(project?.id),
      });
    }
  };

  const handleGetApiKeysByProjectCompleted = (data?: IGetApiKeysByProjectOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { apiKeys } = data;

    if (apiKeys.length) {
      setApiKey(apiKeys[0].guid);
      return;
    }

    const integrationType = localStorage.getItem('integrationType') as ApiKeyType;

    handleCreateApiKey({
      projectId: String(project?.id),
      title: 'API key 1',
      type: integrationType,
    });
  };

  const handleCreateApiKeyCompleted = (data?: ICreateApiKeyOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const {
      apiKey: { id: apiKeyId, guid },
    } = data;

    setApiKey(guid);

    const integrationType = localStorage.getItem('integrationType') as ApiKeyType;

    handleCreateRestriction({
      apiKeyId,
      value: String(project?.name),
      type: integrationType === 'WEB' ? 'HTTP_REFERER' : 'MOBILE_APP',
    });
  };

  const { handleGetLoggedUser } = useGetLoggedUser({
    onCompleted: handleGetLoggedUserCompleted,
  });

  const { handleGetGeladaOrganisationsForConnectedUser, isLoading: isGetGeladaOrganisationsForConnectedUserLoading = false } =
    useGetGeladaOrganisationsForConnectedUser({
      onCompleted: handleGetGeladaOrganisationsForConnectedUserCompleted,
    });

  const { handleGetGeladaProjects, isLoading: isGetGeladaProjectsLoading = false } = useGetGeladaProjects({
    onCompleted: handleGetGeladaProjectsCompleted,
  });

  const handleActivateProjectError = () => {
    return;
  };

  const handleCreateApiKeyError = () => {
    return;
  };

  const { handleActivateProduct, isLoading: isActivateProductLoading = false } = useActivateProduct({});

  const { handleActivateProject, isLoading: isActivateProjectLoading = false } = useActivateProject({
    onCompleted: handleActivateProjectCompleted,
    onError: handleActivateProjectError,
  });

  const { handleGetApiKeysByProject, isLoading: isGetApiKeysByProjectLoading = false } = useGetApiKeysByProject({
    onCompleted: handleGetApiKeysByProjectCompleted,
  });

  const { handleCreateApiKey, isLoading: isCreateApiKeyLoading = false } = useCreateApiKey({
    onCompleted: handleCreateApiKeyCompleted,
    onError: handleCreateApiKeyError,
  });

  const { handleCreateRestriction } = useCreateRestriction();

  useEffect(() => {
    handleGetLoggedUser({
      shouldIncludeUserCredential: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetGeladaOrganisationsForConnectedUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isLoading =
    isActivateProjectLoading ||
    isGetGeladaOrganisationsForConnectedUserLoading ||
    isActivateProductLoading ||
    isGetApiKeysByProjectLoading ||
    isCreateApiKeyLoading ||
    isGetGeladaProjectsLoading;

  return (
    <PageLayout
      additionalClassNames="c-page-layout-template--narrow"
      isPreloaderVisible={isLoading}
      meta={{
        description: 'Let us know where you want to install the plugin',
        metaTitle: PAGE_TITLE,
      }}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {isAuthenticated && (
        <>
          <div className="flex flex-col items-center mb-8">
            <img alt="Kanzi logo" className="w-full max-w-16 mb-6" src="/images/kanzi-logo-icon.svg" />
            <h1 className="h2 mb-2 text-center">{PAGE_TITLE}</h1>
          </div>

          <WelcomeStepProgress currentStep={2} />

          <div className="pt-4 pb-16 text-center">
            <InformationBox
              additionalClassNames="mb-8"
              iconId="id_info_icon"
              message={`Before you can start using Kanzi ${project?.hasMobileApplication ? 'on your app' : 'on your website'} ${
                project?.name ? `${project.name}` : ''
              } the installation script will need to be copied to each page you would like the Kanzi tool to appear. Would you like to install this yourself or get someone to help? Whoever is installing gets access to step-by-step instructions.`}
            />

            <Tab.Group>
              <Tab.List className="mb-12 bg-grey-200 rounded-full inline-flex">
                <TabRound>Install Kanzi myself</TabRound>
                <TabRound>Get someone to help me</TabRound>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <strong>Self-installation</strong>
                  <p className="mb-8">
                    Please copy and paste the script below into your {project?.hasMobileApplication ? 'app' : 'website'}. For more
                    information on installing Kanzi check out our{' '}
                    <NextLink href={`/docs/what-you-can-find-here`}>
                      <a className="c-information-box__link" rel="noopener noreferrer" target="_blank">
                        {' '}
                        help guides
                      </a>
                    </NextLink>
                    .
                  </p>
                  {apiKey ? (
                    <div className="border border-grey-200 flex flex-col md:flex-row rounded">
                      <div ref={scriptRef} className="p-4 text-left">
                        {`<script id="kanzi" data-defaultlanguage="en" data-apikey="${apiKey}" src="https://plugin.kanzi.io/lib/kanzi.js" async></script>`}
                      </div>

                      <div className="flex items-center p-4">
                        <CopyButton
                          copyElementRef={scriptRef}
                          supportiveText="Copy the script"
                          onCopyCompleted={() => handleCopyCompleted('Script')}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="border border-grey-200 p-4 rounded">
                      <Skeleton borderRadius="0.6rem" count={COPY_CODE_SKELETON_COUNT} />
                    </div>
                  )}
                </Tab.Panel>
                <Tab.Panel>
                  <strong>Get help installing Kanzi</strong>
                  <p className="mb-8">
                    Let&#39;s send the install instructions to someone who can help you install the script on your{' '}
                    {project?.hasMobileApplication ? 'app' : 'website'} so you can start using Kanzi today. If you don&#39;t have a
                    developer or someone technical you can use just send it to us{' '}
                    <NextLink href={`mailto:support@kanzi.io`}>
                      <a className="c-information-box__link" rel="noopener noreferrer" target="_blank">
                        {' '}
                        support@kanzi.io
                      </a>
                    </NextLink>{' '}
                    and we will get back in touch with you and help you finalise the installation process.
                  </p>

                  {loggedUser && (
                    <InstructionsEmailForm
                      apiKeyGuid={String(apiKey)}
                      isApp={project?.hasMobileApplication}
                      loggedUser={loggedUser}
                      projectName={project?.name}
                    />
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <div className="mt-12">
              <LinkButton additionalClassNames={`${BUTTON_CLASSES.white} mr-4`} text="Back" url="/welcome/plugin-install-location" />
              <LinkButton additionalClassNames={`${BUTTON_CLASSES.green}`} text="Next" url="/welcome/business-details" />
            </div>
          </div>
        </>
      )}
    </PageLayout>
  );
};

export { InstallInstructionsPage };
