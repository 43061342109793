/* eslint-disable @typescript-eslint/indent */
import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { Accordion, Spinner } from '@netfront/ui-library';
import groupBy from 'lodash.groupby';
import { TreeNodeInArray } from 'react-simple-tree-menu';

import { useApiKeyContext } from '../../../../contexts';
import {
  IGetAvailableVoicesOnCompletedResponse,
  IUpdateApiKeyVoicesOnCompletedResponse,
  useGetAvailableVoices,
  useToast,
  useUpdateApiKeyVoices,
} from '../../../../hooks';
import { IApiKey, IAvailableVoice, IDBAvailableVoice } from '../../../../interfaces';

const VoicesView = () => {
  const { dispatch, state: apiKeys = [] } = useApiKeyContext();
  const { handleToastError } = useToast();

  const [accordionData, setAccordionData] = useState<TreeNodeInArray[]>([]);
  const [allAvailableVoices, setAllAvailableVoices] = useState<IAvailableVoice[]>([]);
  const [apiKeyVoiceIds, setApiKeyVoiceIds] = useState<number[]>([]);
  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey>();

  const handleGetAvailableVoicesCompleted = (data?: IGetAvailableVoicesOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { availableVoices = [] } = data;

    const voicesGroupedByLanguage = groupBy(availableVoices, 'language');

    setAllAvailableVoices(availableVoices);

    setAccordionData(
      Object.keys(voicesGroupedByLanguage).map((language) => {
        const voicesForLanguage = voicesGroupedByLanguage[language];

        const childNodes: TreeNodeInArray[] = voicesForLanguage.map((voice) => ({
          key: String(voice.id),
          label: handleAvailableVoicesChildNodeLabel(voice),
          nodes: [],
          url: `https://kanzi-assets-production.s3.ap-southeast-2.amazonaws.com/${language.toLocaleLowerCase()}-${voice.name.toLocaleLowerCase()}.mp3`,
        }));

        return {
          key: language,
          label: language,
          nodes: childNodes,
        };
      }),
    );

    setHasRequiredPageData(true);
  };

  const handleAvailableVoicesChildNodeLabel = (voice: IDBAvailableVoice) => {
    const { dialect, gender, isChild, name } = voice;

    const splitDialect = dialect.contains('_')
      ? dialect
          .split('_')
          .slice(-1)
          .map((item) => item.toLocaleLowerCase().capitalize())
      : [dialect.toLocaleLowerCase().capitalize()];

    const label = `${name} \u00A0- ${gender.toLocaleLowerCase().capitalize()}, ${
      splitDialect[0].length > 2 ? splitDialect[0] : splitDialect[0].toUpperCase()
    } ${isChild ? ' (child)' : ''}`;

    return label;
  };

  const handleGetAvailableVoicesError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
    });
  };

  const handleToggleSwitchClick = (selectedId: number) => {
    if (!selectedApiKey) {
      return;
    }

    const { language: selectedLanguage } = allAvailableVoices.find(({ id }) => id === selectedId) as IAvailableVoice;

    const otherLanguageVoiceIdsToToggleOff = allAvailableVoices
      .filter(({ id, language }) => id !== selectedId && language === selectedLanguage && apiKeyVoiceIds.includes(id))
      .map(({ id }) => id);

    const filteredApiKeyVoiceIds = apiKeyVoiceIds.filter((id) => !otherLanguageVoiceIdsToToggleOff.includes(id));

    const updatedApiKeyVoiceIds = apiKeyVoiceIds.includes(selectedId)
      ? filteredApiKeyVoiceIds.filter((id) => id !== selectedId)
      : [...filteredApiKeyVoiceIds, selectedId];

    handleUpdateApiKeyVoices({
      apiKeyId: selectedApiKey.id,
      voiceIds: updatedApiKeyVoiceIds,
    });

    setApiKeyVoiceIds(updatedApiKeyVoiceIds);
  };

  const handleUpdateApiKeyVoicesCompleted = (data?: IUpdateApiKeyVoicesOnCompletedResponse) => {
    if (!(data && selectedApiKey)) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      dispatch({
        payload: {
          apiKeyId: selectedApiKey.id,
          voices: allAvailableVoices.filter(({ id }) => apiKeyVoiceIds.includes(id)),
        },
        type: 'updateApiKeyVoices',
      });

      return;
    }

    const {
      MESSAGES: {
        ERROR: { UNEXPECTED },
      },
    } = COMMON_LIBRARY_CONSTANTS;

    handleToastError({
      error: new Error(UNEXPECTED),
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleUpdateApiKeyVoicesError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetAvailableVoices, isLoading: isGetAvailableVoicesLoading = false } = useGetAvailableVoices({
    onCompleted: handleGetAvailableVoicesCompleted,
    onError: handleGetAvailableVoicesError,
  });

  const { handleUpdateApiKeyVoices, isLoading: isUpdateApiKeyVoicesLoading = false } = useUpdateApiKeyVoices({
    onCompleted: handleUpdateApiKeyVoicesCompleted,
    onError: handleUpdateApiKeyVoicesError,
  });

  useEffect(() => {
    handleGetAvailableVoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiKeys.length) {
      return;
    }

    setSelectedApiKey(apiKeys.find(({ isSelected }) => isSelected));
  }, [apiKeys]);

  useEffect(() => {
    if (!selectedApiKey) {
      return;
    }

    const { voices = [] } = selectedApiKey;

    setApiKeyVoiceIds(voices.map(({ id }) => id));
  }, [selectedApiKey]);

  const isLoading = isGetAvailableVoicesLoading || isUpdateApiKeyVoicesLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      {hasRequiredPageData && selectedApiKey && (
        <div className="p-4">
          <h3>Voices</h3>
          <Accordion
            data={accordionData}
            selectedIds={apiKeyVoiceIds}
            isDisplayPlayAudioButton
            onToggleSwitchClick={handleToggleSwitchClick}
          />
        </div>
      )}
    </>
  );
};

export { VoicesView };
