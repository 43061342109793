import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS, getApolloErrorMessages } from '@netfront/common-library';

const getToastErrorMessages = ({
  error,
  shouldUseFriendlyErrorMessage = false,
}: {
  error: ApolloError | Error | string;
  shouldUseFriendlyErrorMessage: boolean;
}): string[] => {
  const {
    MESSAGES: {
      ERROR: { UNEXPECTED },
    },
  } = COMMON_LIBRARY_CONSTANTS;

  let errorMessages = [UNEXPECTED];

  if (!shouldUseFriendlyErrorMessage) {
    return errorMessages;
  }

  if (error instanceof ApolloError) {
    errorMessages = getApolloErrorMessages(error);
  } else if (error instanceof Error) {
    errorMessages = [error.message];
  }

  return errorMessages;
};

export { getToastErrorMessages };
