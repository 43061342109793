import { useState } from 'react';

import { IconBin } from '../Icons/IconBin';

import { CARD_LOGOS, RADIO_SET_NAME } from './PaymentCardOption.constants';
import { PaymentCardOptionProps } from './PaymentCardOption.interfaces';

const PaymentCardOption = ({
  cardBrand,
  default: isDefaultCard,
  isDeletable,
  lastFourDigits,
  selectedValue,
  stripeSourceId,
  onChange,
  onDelete,
}: PaymentCardOptionProps) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const onMouseEnter = () => setIsMouseEnter(true);
  const onMouseLeave = () => setIsMouseEnter(false);

  const isSelected = selectedValue === stripeSourceId;
  const isHighlighted = isSelected || isMouseEnter;

  return (
    <div
      key={stripeSourceId}
      className={`flex items-center border-2 rounded-sm px-4 py-3 mb-2 hover:border-primary ${
        isHighlighted ? 'border-primary' : 'border-transparent'
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex items-center relative">
        <input
          checked={isSelected}
          className="absolute w-full h-full pointer opacity-0"
          id={stripeSourceId}
          name={RADIO_SET_NAME}
          type="radio"
          value={stripeSourceId}
          onChange={onChange}
        />
        <label className="pointer flex items-center" htmlFor={stripeSourceId}>
          <div className={`w-4 h-4 border rounded-circle ${isSelected ? 'bg-primary border-primary' : 'bg-white border-grey-300'}`}></div>
          <div
            className={`flex items-center justify-center py-4 px-4 border-2 rounded ml-2 mr-4 w-20 h-14 ${
              isHighlighted ? 'border-primary' : 'border-grey-200'
            }`}
          >
            <img alt={cardBrand} src={CARD_LOGOS[cardBrand.toLowerCase()]} />
          </div>
          {`•••• •••• •••• ${lastFourDigits}`}
        </label>
      </div>
      <div className="flex items-center ml-auto">
        {isDefaultCard && <span className="color-primary weight-600">Default</span>}
        {isHighlighted && isDeletable && (
          <button
            className="bg-red-400 color-white border-none p-1 ml-4 rounded-circle pointer flex items-center justify-center"
            title="Delete card"
            onClick={() =>
              onDelete({
                cardBrand,
                default: isDefaultCard,
                lastFourDigits,
                stripeSourceId,
              })
            }
          >
            <span className="sr-only">Delete card</span>
            <IconBin />
          </button>
        )}
      </div>
    </div>
  );
};

export { PaymentCardOption };
