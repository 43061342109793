import { useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { SubscribeForm } from '../Forms/SubscribeForm';
import { BUTTON_CLASSES, LinkButton } from '../LinkButton';

import { PUBLIC_FOOTER_MENU_ITEMS } from './PublicFooter.constants';

const PublicFooter = () => {
  const { pathname } = useRouter();

  const [hasSubscribeForm, setHasSubscribeForm] = useState<boolean>(false);

  const pathsWithHiddenSubscribeForm = ['/authenticate', '/login', '/thank-you', '/[pageUrl]', '/404', '/docs/[pageUrl]', '/partners'];

  const menuItemCategories = new Set(PUBLIC_FOOTER_MENU_ITEMS.filter((item) => item.category !== undefined).map((item) => item.category));

  useEffect(() => {
    setHasSubscribeForm(!pathsWithHiddenSubscribeForm.includes(pathname));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <hr className="h-1 w-2/3 border-none bg-grey-200 block md:none" />
      <footer className="bg-white py-16 pt-0">
        {hasSubscribeForm && (
          <div className="bg-gradient-wave bg-gradient-wave--minimal mb-12 py-20 lg:py-28">
            <div className="c-container c-container--small">
              <div className="flex flex-wrap items-center -mx-8">
                <div className="w-full lg:w-1/2 px-8 text-center">
                  <h3 className="h2 font-header color-white mb-2">Join the tribe</h3>
                  <p className="color-white">Tips, insights and feature announcments</p>
                </div>
                <div className="w-full lg:w-1/2 px-8">
                  <SubscribeForm listId={String(process.env.REACT_APP_CREATE_WEBSITE_SUBSCRIBER_CLIENT_ID)} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="c-container">
          <div className="flex flex-col items-center md:row-reverse flex-wrap -mx-4">
            <div className="flex flex-col md:flex-row justify-center md:justify-end mb-12 md:mb-0 px-4 w-full md:w-1/3">
              {[...menuItemCategories].map((menuCategory, key) => (
                <nav
                  key={`${String(menuCategory)}-${key}`}
                  aria-label={`${String(menuCategory)} navigation`}
                  className="mb-4 md:mb-0 md:ml-12"
                >
                  <span className="h5 weight-600 block mb-4">{menuCategory}</span>
                  <ul className="list-none p-0 mb-0 text-center md:text-left">
                    {PUBLIC_FOOTER_MENU_ITEMS.filter(({ category }) => category === menuCategory).map(({ href, label }, index) => (
                      <li key={`${label}-${index}`} className={index === PUBLIC_FOOTER_MENU_ITEMS.length - 1 ? 'mb-0 ml-0' : 'mb-4 ml-0'}>
                        <LinkButton additionalClassNames={`${BUTTON_CLASSES['subtle-link']} color-black`} text={label} url={href} />
                      </li>
                    ))}
                  </ul>
                </nav>
              ))}
            </div>

            <div className="flex items-center justify-center flex-1 mb-12 md:mb-0 md:mt-8 px-4 w-full md:w-1/3">
              <Link href="https://www.facebook.com/kanzi.io.tribe" passHref>
                <a className="mr-4" target="_blank">
                  <span className="sr-only">Link for Kanzi facebook</span>
                  <img alt="Facebook icon" loading="lazy" src="/images/facebook.svg" />
                </a>
              </Link>
              <Link href="https://www.instagram.com/kanzi.io.tribe/" passHref>
                <a className="mr-4" target="_blank">
                  <span className="sr-only">Link for Kanzi instagram</span>
                  <img alt="Instagram icon" loading="lazy" src="/images/instagram.svg" />
                </a>
              </Link>
              <Link href="https://twitter.com/kanzi_io_tribe" passHref>
                <a className="mr-4" target="_blank">
                  <span className="sr-only">Link for Kanzi twitter</span>
                  <img alt="Twitter icon" loading="lazy" src="/images/twitter.svg" />
                </a>
              </Link>
              <Link href="https://www.linkedin.com/company/kanzi-io/?viewAsMember=true" passHref>
                <a className="mr-4" target="_blank">
                  <span className="sr-only">Link for Kanzi linkedin</span>
                  <img alt="Linkedin icon" loading="lazy" src="/images/linkedin.svg" />
                </a>
              </Link>
            </div>

            <div className="flex flex-col items-center md:items-start md:flex px-4 w-full md:w-1/3">
              <img alt="Kanzi" className="mb-8 max-w-20 md:max-w-24" loading="lazy" src="/images/kanzi-logo-full-dark.svg" />
              <small>&copy; {new Date().getFullYear()}, Kanzi. All rights reserved.</small>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export { PublicFooter };
