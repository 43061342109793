import { ChangeEvent, useEffect, useState } from 'react';

import { IContentPage, IDBContentPage } from '@netfront/ekardo-content-library';
import { CardList, CoverLink, Input, PagePreviewCard, IImage, Button } from '@netfront/ui-library';

import { BlogLandingPageProps } from './BlogLandingPage.interfaces';

import { PublicPageLayout } from '../../PublicPageLayout';

const BlogCard = ({ asset, title, url, tags }: IDBContentPage) => {
  const [imageUrl, setImageUrl] = useState<string>();
  useEffect(() => {
    if (!asset) {
      return;
    }

    const { presignedUrl } = asset;

    setImageUrl(presignedUrl);
  }, [asset]);

  const blogImage: IImage = {
    altText: title,
    src: String(imageUrl),
  };

  return (
    <PagePreviewCard
      key={title}
      coverLink={<CoverLink href={`/blog/${url}`} supportiveText={`Go to article about ${title}`} />}
      image={blogImage}
      isHero={tags?.find((r) => r.name === 'Top Blog') !== undefined}
      subTitle={tags?.[0]?.name}
      title={title}
    />
  );
};

export const BlogLandingPage = ({ contentPages }: BlogLandingPageProps) => {
  const [allBlogPages, setAllBlogPages] = useState<IDBContentPage[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [filteredBlogPages, setFilteredBlogPages] = useState<IDBContentPage[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const filterBySearchText = (inputText?: string) => (blogPage: IDBContentPage) => {
    if (!inputText) {
      return true;
    }

    const { tags = [], title } = blogPage;

    if (!tags.length) {
      return false;
    }

    const [firstTag] = tags;

    const { name } = firstTag;

    return name.contains(inputText) || title.contains(inputText);
  };

  const filterByTag = (inputTags: string[]) => (blogPage: IContentPage) => {
    if (!inputTags.length) {
      return false;
    }

    const { tags = [] } = blogPage;

    if (!tags.length) {
      return false;
    }

    const [blogPageTag] = tags;

    return inputTags.includes(blogPageTag.name);
  };

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilteredBlogPages(allBlogPages.filter(filterBySearchText(value)).filter(filterByTag(selectedTags)));
    setSearchText(value);
  };

  const handleTagClick = (value: string) => {
    const updatedSelectedTags = selectedTags.includes(value)
      ? selectedTags.filter((selectedTag) => selectedTag !== value)
      : [...selectedTags, value];

    setFilteredBlogPages(allBlogPages.filter(filterByTag(updatedSelectedTags)).filter(filterBySearchText(searchText)));
    setSelectedTags(updatedSelectedTags);
  };

  useEffect(() => {
    const pageTags: string[] = [];
    contentPages.forEach((page) => page.tags?.forEach((tag) => pageTags.push(tag.name)));

    const uniqueSortedFlattenedTags = [...new Set(pageTags)];

    setAllBlogPages(contentPages);
    setAllTags(uniqueSortedFlattenedTags);
    setFilteredBlogPages(contentPages);
    setSelectedTags(uniqueSortedFlattenedTags);
  }, [contentPages]);

  const blogItems = filteredBlogPages.map((page, index) => {
    return <BlogCard {...page} key={index} />;
  });

  return (
    <PublicPageLayout
      afterTitle="Find out how Kanzi can help you grow your audience"
      meta={{
        metaTitle: 'Find articles and posts about Kanzi services',
        description: 'The Kanzi blog provides information on how the tool can help, industry trends and much more',
      }}
      title="Blog"
    >
      <div className="mb-8 md:mb-12 md:flex">
        <Input
          additionalClassNames="max-w-full mb-4 md:mb-0 md:mr-0 md:w-88 mr-4 p-4"
          id="search-blog"
          labelText="Search Blog"
          name="search blog"
          placeholder="Search"
          type="text"
          isLabelHidden
          onChange={handleSearchTextChange}
        />

        <div className="flex flex-wrap">
          {allTags.map((tag) => {
            const isTagSelected = selectedTags.includes(tag);

            return (
              <Button
                key={tag}
                additionalClassNames="mr-2 my-1"
                text={tag}
                variant={isTagSelected ? 'primary' : 'primary-inverse'}
                onClick={() => handleTagClick(tag)}
              />
            );
          })}
        </div>
      </div>
      {Boolean(blogItems.length) && <CardList additionalClassNames="font-header gap-0" items={blogItems} />}
    </PublicPageLayout>
  );
};
