import { useRef, useState, RefObject } from 'react';

import { useOutsideClick } from '@netfront/common-library';
import { ColorPicker as NextfrontColorPicker, ColorPickerPreview } from '@netfront/ui-library';

import { ColorPickerProps } from './ColorPicker.interfaces';

const ColorPicker = ({ color, onChange, id }: ColorPickerProps) => {
  const pickerRef = useRef() as RefObject<HTMLDivElement>;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onPreviewToggle = () => setIsOpen(!isOpen);

  useOutsideClick(pickerRef, () => setIsOpen(false));

  return (
    <>
      <div ref={pickerRef} className="c-color-picker" id={id}>
        <ColorPickerPreview selectedColor={color} onClick={onPreviewToggle} />
        {isOpen && (
          <div className="c-color-picker__preview">
            <NextfrontColorPicker color={color} onChange={onChange} />
          </div>
        )}
      </div>
    </>
  );
};

export { ColorPicker };
