import { useState, useEffect, ChangeEvent } from 'react';

import { ApolloError } from '@apollo/client';
import { DateRangePicker, Button, IDateRange, IOption, Select, Table, Toolbar, Spinner } from '@netfront/ui-library';
import { addMonths } from 'date-fns';
import { useRouter } from 'next/router';

import { TranslateTableViewProps } from './TranslateTableView.interfaces';

import { Pagination, SearchFilter } from '../../../../../components';
import { useExportUsageReport, useGetProjectUsage, useToast } from '../../../../../hooks';
import { REPORTING_PAGE_CONSTANTS } from '../../ReportingPage.constants';
import { IProjectUsageTableData, ITablePageNumberPageInfo } from '../../ReportingPage.interfaces';

const { numberOfPageSizeOptions, pageSize, tableColumnsTextSpeech, tableSelectionItems } = REPORTING_PAGE_CONSTANTS;

const TranslateTableView = ({ selectedTable, tableData, onProjectUserTableData, onTableChange }: TranslateTableViewProps) => {
  const { query } = useRouter();
  const { handleToastError } = useToast();

  const { projectId } = query;

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [currentPageNumberPageInfos, setCurrentPageNumberPageInfos] = useState<ITablePageNumberPageInfo[]>([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState<boolean>(true);
  const [nextCursor, setNextCursor] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<IDateRange>({
    end: addMonths(new Date(), 1),
    start: addMonths(new Date(), -1),
  });
  const [tablePageSize, setTablePageSize] = useState<number>(pageSize);

  const [tablePageSizeOptions] = useState<IOption[]>(
    (Array.from({ length: numberOfPageSizeOptions }).fill(tablePageSize) as number[]).map((item, index) => {
      const value = item * (index + 1);

      const option: IOption = {
        id: index,
        name: value.toString(),
        value,
      };

      return option;
    }),
  );
  const [totalTablePages, setTotalTablePages] = useState<number>(0);

  const getNextCursor = (pageNumber: number): string | undefined => {
    if (!pageNumber) {
      return undefined;
    }

    if (pageNumber === 1) {
      return undefined;
    }

    const tablePageNumberPageInfo = currentPageNumberPageInfos.find(
      (currentTablePageNumberPageInfo) => currentTablePageNumberPageInfo.pageNumber === pageNumber - 1,
    );

    if (!tablePageNumberPageInfo) {
      return undefined;
    }

    const {
      pageInfo: { endCursor },
    } = tablePageNumberPageInfo;

    return endCursor;
  };

  const handleApolloError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const handleClearSearchFilter = () => {
    setSearchValue('');

    const { end, start } = selectedDate;

    handleGetProjectUsage({
      after: nextCursor,
      first: tablePageSize,
      from: start,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId: String(projectId),
      to: end,
    });
  };

  const handleDateRangePickerChange = (date: IDateRange) => {
    setSelectedDate(date);
  };

  const handleDisplayTableClick = () => {
    setNextCursor(undefined);
    handleGenerateTranslateReport();
  };

  const handleDownloadReport = (date: IDateRange) => {
    const { end, start } = date;

    handleExportUsageReport({
      from: start,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId: String(projectId),
      to: end,
    });
  };

  const handleGenerateTranslateReport = () => {
    const { end, start } = selectedDate;

    handleGetProjectUsage({
      after: nextCursor,
      filter: searchValue,
      first: tablePageSize,
      from: start,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId: String(projectId),
      to: end,
    });
  };

  const handleNextButtonClick = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    setIsPreviousButtonDisabled(pageNumber === 1);
    setNextCursor(getNextCursor(pageNumber));
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setCurrentPageNumber(1);
    setIsPreviousButtonDisabled(true);
    setNextCursor(undefined);
    setTablePageSize(Number(value));
  };

  const handlePreviousButtonClick = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    setIsPreviousButtonDisabled(pageNumber === 1);
    setNextCursor(getNextCursor(pageNumber));
  };

  const { handleExportUsageReport, isLoading: isExportUsageReportLoading = false } = useExportUsageReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      window.open(presignedUrl, '_blank');
    },
    onError: (error) => {
      handleApolloError(error);
    },
  });

  const { handleGetProjectUsage, isLoading: isGetProjectUsageLoading = false } = useGetProjectUsage({
    fetchPolicy: 'no-cache',
    onCompleted: ({ edges, pageInfo, totalCount = 0 }) => {
      const { hasNextPage } = pageInfo;

      setCurrentPageNumberPageInfos((currentValues) => {
        const pageNumberPageInfo: ITablePageNumberPageInfo = {
          pageNumber: currentValues.length ? currentPageNumber : 1,
          pageInfo,
        };

        if (!currentValues.length) {
          return [pageNumberPageInfo];
        }

        const tablePageNumberPageInfo = currentValues.find(({ pageNumber }) => pageNumber === currentPageNumber);

        if (tablePageNumberPageInfo) {
          return currentValues.map((currentValue) => {
            const { pageNumber } = currentValue;
            return pageNumber === pageNumberPageInfo.pageNumber ? pageNumberPageInfo : currentValue;
          });
        }

        return [...currentValues, pageNumberPageInfo];
      });

      setIsNextButtonDisabled(!hasNextPage);

      onProjectUserTableData(edges);

      setTotalTablePages(Math.ceil(totalCount / tablePageSize));
    },
    onError: (error) => {
      handleApolloError(error);
    },
  });

  useEffect(() => {
    if (currentPageNumber === 1 && !tableData) {
      return;
    }

    handleGenerateTranslateReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, tablePageSize]);

  const isLoading = isExportUsageReportLoading || isGetProjectUsageLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <div className="px-4 w-full">
        <Toolbar
          additionalClassNames="c-reporting-page__table-toolbar"
          childrenEnd={
            <div className="flex gap-2">
              <Button text="Display" type="button" onClick={handleDisplayTableClick} />
              <Button text="Download" type="button" onClick={() => handleDownloadReport(selectedDate)} />
            </div>
          }
          childrenMiddle={
            <div className="c-reporting-page__table-toolbar__middle flex flex-1 gap-4">
              <DateRangePicker selectedDate={selectedDate} onChangeHandler={handleDateRangePickerChange} />
              <Select
                id="Select table"
                labelText=""
                name="Select table"
                options={tableSelectionItems}
                value={selectedTable}
                isLabelHidden
                onChange={(event: ChangeEvent<HTMLSelectElement>) => onTableChange(event)}
              />
            </div>
          }
          childrenStart={
            <SearchFilter
              additionalClassNames="c-search-filter"
              id="searchTranslateSpeechTable"
              labelText="Translate & speech filter"
              name="searchTranslateSpeechTable"
              placeholder="Search"
              type="text"
              value={searchValue}
              isLabelHidden
              onChange={handleChangeSearchValue}
              onClear={handleClearSearchFilter}
              onSearch={handleGenerateTranslateReport}
            />
          }
        />
        {tableData ? (
          <>
            {!tableData.length && <div className="mt-8">There&apos;s no project usage data available yet</div>}

            {Boolean(tableData.length) && (
              <>
                <div className="mt-8">
                  <Table<IProjectUsageTableData> columns={tableColumnsTextSpeech} data={tableData} />
                </div>

                <div className="c-reporting-page__pagination mt-8">
                  <Pagination
                    isNextButtonDisabled={isNextButtonDisabled}
                    isPreviousButtonDisabled={isPreviousButtonDisabled}
                    tablePageNumber={currentPageNumber}
                    tablePageSize={tablePageSize}
                    tablePageSizeOptions={tablePageSizeOptions}
                    totalTablePages={totalTablePages}
                    onNextButtonClick={() => handlePreviousButtonClick(currentPageNumber - 1)}
                    onPageSizeChange={handlePageSizeChange}
                    onPreviousButtonClick={() => handleNextButtonClick(currentPageNumber + 1)}
                  />
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export { TranslateTableView };
